import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType

{
  message: object;
  data: object;
}

interface InvalidResponseType
 {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isClient?: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showOldPassword: boolean;
  oldPassword: string;
  showNewPassword: boolean;
  newPassword: string;
  showConfirmPassword: boolean;
  confirmPassword: string;
  confirmValidation: boolean;
  errorMessage: string;
  upperCaseCheck:boolean;
  numberCheck:boolean;
  specialCharCheck:boolean;
  requiredError:boolean;
  passwordErr: string;
  oldPasswordError:string;
  currentPassword:string;
  alertSamePopUp:boolean;
  alertSuccessPopUp:boolean;
  toggleEmail:boolean;
  openPasswordModal: boolean;
  isDialogOpen:boolean;
    // Customizable Area End
}
interface SS { }

export default class FreelancerPasswordSettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  forgatePasswordAPICallID:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.forgatePasswordAPICallID='';
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {  
      showOldPassword:false,
      oldPassword:'',
      showNewPassword: false,
      newPassword:'',
      showConfirmPassword: false,
      confirmPassword:'',
      confirmValidation:false,
      errorMessage:'',
      upperCaseCheck:false,
      numberCheck:false,
      specialCharCheck:false,
      requiredError:false,
      passwordErr:"",
      oldPasswordError:"",
      currentPassword:"",
      alertSamePopUp:false,
      alertSuccessPopUp:false,
      toggleEmail:true,
      openPasswordModal: false,
      isDialogOpen:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({currentPassword:await storage.get("UserPassword")});
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
  
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = (event:any) => {
    this.setState({ toggleEmail: event.target.checked });
  };
  
  handleOpenPasswordModal = () => {
    this.setState({ openPasswordModal: true });
  };

  handleClosePasswordModal = () => {
    this.setState({ openPasswordModal: false });
  };

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true, openPasswordModal: false  });
    setTimeout(() => {
      this.setState({ isDialogOpen: false });
    }, 3000);
  };
  
  // Customizable Area End
}
