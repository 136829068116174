import React from 'react';
import { Grid, Avatar, Typography, Button, Box, Tabs, Tab, Link, styled } from '@material-ui/core';
import FreelancerCompleteProfileController,
{ Props }
    from './FreelancerCompleteProfileController.web';
import FreelancerNavBar from '../../dashboard/src/FreelancerNavBar.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import { edit, add, facebook, medium, twitter, projectPic, outlineEdit, online } from './assets';
import Rating from '@material-ui/lab/Rating';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded'
const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorInherit': {
        color: '#011342',
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    '& .MuiTabs-indicator': {
        display: 'none'
    },
    '& .MuiTab-root': {
        maxWidth: "40%"
    },
    '& .MuiTab-root.Mui-selected': {
        maxWidth: "40%",
        borderBottom: '2px solid #364BA0'
    },
})
export default class FreelancerCompleteProfile extends FreelancerCompleteProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
     renderArticles = () => {
        const articles = [
            {
                title: "Guide to master coding",
                content:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
                link: "Link to Article",
            },
            {
                title: "Guide to master coding",
                content:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
                link: "Link to Article",
            },
            {
                title: "Guide to master coding",
                content:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
                link: "Link to Article",
            },
        ];

        return articles.map((article, index) => (
            <Box key={index} style={{ display: "flex", marginTop: "24px" }}>
                <Box>
                    <Typography style={webStyles.subBoldHeading}>{article.title}</Typography>
                    <Typography style={{ ...webStyles.subText, fontSize: "16px", marginTop: "16px" }}>{article.content}</Typography>
                </Box>
                <Link href="#" style={{ ...webStyles.educationDateStyle, fontSize: "14px", color: "#364BA0" }} >
                    {article.link}{" "}
                    <span style={{ marginLeft: "4px", fontSize: "14px" }}>↗</span>
                </Link>
            </Box>
        ));
    };


    render() {

        return (
            <>
                <FreelancerNavBar navigation={undefined} />
                <Grid style={{ padding: '48px 100px 48px 79px' }} container spacing={3}>
                    <Grid container spacing={5}>
                        {/* Left section */}
                        <Grid item xs={12} sm={4}>
                            <Box style={webStyles.leftSectionStyle}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <div style={{position:"relative"}}>
                                        <img src={online} style={{position:"absolute",top: 4,left: 7,zIndex: 30}}/>
                                        <Avatar alt="Rahul Sharma" src="/path/to/avatar.jpg" style={{ width: 72, height: 72, marginBottom: "18px" }} />
                                        <img src={outlineEdit} style={{position:"absolute",left:42,top:53}}/>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ ...webStyles.mainHeadingBold, marginBottom: "18px" }}>
                                            Rahul Sharma
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginBottom: "18px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button variant="contained" style={webStyles.btnStyleTans}>
                                                    See Public View
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="outlined" style={webStyles.btnStylesBlue}>Profile Settings</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Languages</Typography>
                                        <Box>
                                            <img src={add} style={webStyles.imageMargin} />
                                            <img src={edit} />
                                        </Box>
                                    </Box>

                                    <div style={{ marginBottom: "12px" }}>
                                        <Typography component="span" style={webStyles.subBoldHeading}>English: </Typography>
                                        <span style={webStyles.subNormalHeading}>Fluent</span>
                                    </div>

                                    <div>
                                        <Typography component="span" style={webStyles.subBoldHeading}>Hindi: </Typography>
                                        <span style={webStyles.subNormalHeading}>Native or Bilingual</span>
                                    </div>
                                </Box>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Social Media</Typography>
                                        <Box>
                                            <img src={add} style={webStyles.imageMargin} />
                                            <img src={edit} />
                                        </Box>
                                    </Box>

                                    <Grid container spacing={1}>
                                        <Grid item style={{ display: "flex", gap: "10px" }}>
                                            <img src={twitter} />
                                            <img src={facebook} />
                                            <img src={medium} />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Services</Typography>
                                        <Box>
                                            <img src={edit} />
                                        </Box>
                                    </Box>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Box style={{
                                                background: "#E4F5FF",
                                                borderRadius: "26px",
                                                display: "flex",
                                                padding: "8px",
                                                gap: "6px",
                                                justifyContent: "center",
                                            }}>
                                                <Typography style={webStyles.skillStyle}>{"Service Desk Management"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box style={webStyles.chipStyle}>
                                                <Typography style={webStyles.skillStyle}>{"Remote Support"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box style={webStyles.chipStyle}>
                                                <Typography style={webStyles.skillStyle}>{"End User Testing"}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box style={webStyles.marginBottomStyle} mt={3} >
                                    <Box style={{ marginBottom: "20px", ...webStyles.headingDisplay }}>
                                        <Typography style={webStyles.headingBold}>Area of Expertise</Typography>
                                        <Box>
                                            <img src={edit} />
                                        </Box>
                                    </Box>
                                    <Grid spacing={1} container>
                                        <Grid item>
                                            <Box style={webStyles.chipStyle}>
                                                <Typography style={webStyles.skillStyle}>{"IP Licensing"}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Industry Focus</Typography>
                                        <Box>
                                            <img src={edit} />
                                        </Box>
                                    </Box>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Box style={{
                                                justifyContent: "center",
                                                gap: "6px",
                                                borderRadius: "26px",
                                                padding: "8px",
                                                display: "flex",
                                                background: "#E4F5FF",
                                            }}>
                                                <Typography style={webStyles.skillStyle}>{"Consumer electronics"}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "21px" }}>
                                        <Typography style={webStyles.headingBold}>Skills</Typography>
                                        <Box><img src={edit} /></Box>
                                    </Box>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Box style={{
                                                borderRadius: "26px",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: "6px",
                                                background: "#E4F5FF",
                                                padding: "8px",
                                            }}>
                                                <Typography style={webStyles.skillStyle}>{"Analytical reasoning"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box style={webStyles.chipStyle}>
                                                <Typography style={webStyles.skillStyle}>{"Research"}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            {/* leftSection2 */}
                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: "24px" }}>
                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Education</Typography>
                                        <Box>
                                            <img src={add} style={webStyles.imageMargin} />
                                            <img src={edit} />
                                        </Box>
                                    </Box>
                                    <Typography style={{ ...webStyles.sub16fontStyle, color: "#49454F", marginBottom: "6px" }}>National Law School of India University (NLSIU), Bangalore</Typography>
                                    <Typography style={{ ...webStyles.sub14fontStyle, fontWeight: 400 }}>Bachelors of Technology</Typography>
                                    <Typography style={webStyles.educationDateStyle}>August 2012- June 2016 </Typography>
                                    <Typography style={webStyles.sub14fontStyle}>India</Typography>
                                </Box>

                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Certifications</Typography>
                                    <Box>
                                        <img src={add} style={webStyles.imageMargin} />
                                        <img src={edit} />
                                    </Box>
                                </Box>
                                <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "6px" }}>ICAI</Typography>
                                <Typography style={{ ...webStyles.sub16fontStyle, fontWeight: 400, marginBottom: "0px" }}>IPR Certification</Typography>
                                <Typography style={webStyles.educationDateStyle}>August 2012- June 2016 </Typography>
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: "24px" }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>License</Typography>
                                    <Box>
                                        <img src={edit} />
                                    </Box>
                                </Box>
                                <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "6px" }}>Bar License</Typography>
                                <Typography style={{ ...webStyles.sub16fontStyle, fontWeight: 400, marginBottom: "0px" }}>State Bar Association</Typography>
                                <Typography style={webStyles.educationDateStyle}>No Expiry</Typography>
                            </Box>
                        </Grid>

                        {/* Right section */}
                        <Grid item xs={12} sm={8} >
                            <Box style={webStyles.leftSectionStyle}>
                                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={webStyles.headingBold}>Copyright Specialist</Typography>
                                        <img src={edit} alt="Edit" style={{ marginLeft: "24px" }} />
                                    </Box>

                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={webStyles.headingBold}>$20.00/Hr</Typography>
                                        <img src={edit} alt="Edit" style={{ marginLeft: "24px" }} />
                                    </Box>
                                </Box>

                                <Typography style={webStyles.subText}>
                                    Greetings, fellow software enthusiasts! I'm thrilled to see your interest in exploring my profile.
                                    I'm Nguyen Shane, a 24-year-old software engineer from the United Kingdom.
                                    My educational path led me to earn a Bachelor's Degree in Computer Science, specializing in Software Engineering.
                                    With this qualification, I'm equipped to dive deep into the world of coding and development,
                                    ready to tackle exciting projects and contribute to cutting-edge technological advancements.
                                </Typography>
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Patents</Typography>
                                    <Box>
                                        <img src={add} style={webStyles.imageMargin} />
                                        <img src={edit} />
                                    </Box>
                                </Box>

                                <div style={{ marginBottom: "24px" }}>
                                    <Typography style={webStyles.sub16fontStyle} >Personal Or Domestic Articles</Typography>
                                    <Typography style={webStyles.sub14fontStyle}>A01B SOIL WORKING IN AGRICULTURE OR FORESTRY; PARTS, DETAILS, OR ACCESSORIES
                                        OF AGRICULTURAL MACHINES OR IMPLEMENTS, IN GENERAL.</Typography>
                                </div>

                                <div style={{ marginBottom: "24px" }}>
                                    <Typography style={webStyles.sub16fontStyle} >Personal Or Domestic Articles</Typography>
                                    <Typography style={webStyles.sub14fontStyle}>A01B SOIL WORKING IN AGRICULTURE OR FORESTRY; PARTS, DETAILS, OR ACCESSORIES
                                        OF AGRICULTURAL MACHINES OR IMPLEMENTS, IN GENERAL.</Typography>
                                </div>

                                <div style={{ marginBottom: "24px" }}>
                                    <Typography style={webStyles.sub16fontStyle} >Personal Or Domestic Articles</Typography>
                                    <Typography style={webStyles.sub14fontStyle}>A01B SOIL WORKING IN AGRICULTURE OR FORESTRY; PARTS, DETAILS, OR ACCESSORIES
                                        OF AGRICULTURAL MACHINES OR IMPLEMENTS, IN GENERAL.</Typography>
                                </div>
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Work History</Typography>
                                    <Box>
                                        <img src={add} style={webStyles.imageMargin} />
                                        <img src={edit} />
                                    </Box>
                                </Box>

                                <Box style={webStyles.lightBlueBox}>
                                    <div style={webStyles.headingDisplay}>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }} >ABC Company</Typography>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>$3000</Typography>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Rating
                                            name="read-only"
                                            value={this.state.ratingNumber}
                                            readOnly
                                            icon={<StarRoundedIcon fontSize="inherit" />}
                                            emptyIcon={<StarBorderRoundedIcon fontSize="inherit" style={webStyles.starIcon} />}
                                        />
                                        <span style={webStyles.rating}>{this.state.rating} </span>
                                        <span>{"|"}</span>
                                        <span style={{
                                            ...webStyles.sub14fontStyle,
                                            marginLeft: "8px",
                                        }}> Mar 6, 2020 - Oct 12, 2020</span>
                                    </div>
                                    <Typography style={{ ...webStyles.subText, marginTop: "24px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....
                                    </Typography>
                                </Box>

                                <Box style={{ ...webStyles.lightBlueBox, marginTop: "16px" }}>
                                    <div style={webStyles.headingDisplay}>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }} > Company</Typography>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>$3500</Typography>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Rating
                                            value={this.state.ratingNumber}
                                            readOnly
                                            icon={<StarRoundedIcon fontSize="inherit" />}
                                            emptyIcon={<StarBorderRoundedIcon fontSize="inherit" style={webStyles.starIcon} />}
                                            name="read-only"
                                        />
                                        <span style={webStyles.rating}>{this.state.rating} </span>
                                        <span>{"|"}</span>
                                        <span style={{
                                            ...webStyles.sub14fontStyle,
                                            marginLeft: "8px",
                                        }}>feb 6, 2020 - Oct 22, 2008</span>
                                    </div>
                                    <Typography style={{ ...webStyles.subText, marginTop: "24px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....
                                    </Typography>
                                </Box>

                                <Button style={webStyles.seeMoreBtn}>See More</Button>
                            </Box>


                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Experience</Typography>
                                    <Box>
                                        <img src={add} style={webStyles.imageMargin} />
                                        <img src={edit} />
                                    </Box>
                                </Box>
                                <div style={webStyles.headingDisplay}>
                                    <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }} >IP Lawyer</Typography>
                                    <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>March 2020 - Present</Typography>
                                </div>
                                <Typography style={{ ...webStyles.sub14fontStyle, fontWeight: 400 }}>Baker McKenzie LLP</Typography>
                                <Typography style={{ ...webStyles.subText, marginTop: "24px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....
                                </Typography>
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Projects</Typography>
                                    <Box>
                                        <img src={add} />
                                    </Box>
                                </Box>
                                <Grid container xs={12} spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Box style={webStyles.projectBoxes}>
                                            <Box>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div>
                                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>XYZ Project </Typography>
                                                        <Typography style={webStyles.sub14fontStyle}>Duration - Mar 6, 2020 - Oct 12, 2020</Typography>
                                                    </div>
                                                    <div>
                                                        <img src={edit} />
                                                    </div>
                                                </div>
                                                <img src={projectPic} style={{ width: "100%", marginTop: "24px" }} />
                                                <Typography style={{ ...webStyles.subText, marginTop: "24px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>

                                </Grid>
                                <Button style={webStyles.seeMoreBtn}>See More</Button>
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Achievements</Typography>
                                    <Box>
                                        <img src={add} style={webStyles.imageMargin} />
                                        <img src={edit} />
                                    </Box>
                                </Box>
                                <CustomTabs
                                    value={this.state.selectedTab}
                                    onChange={this.handleTabChange}
                                    style={webStyles.sub16fontStyle}
                                >
                                    <Tab label="Public Relation Materials (07)" />
                                    <Tab label="Publications (05)" />
                                    <Tab label="Seminars (08)" />
                                </CustomTabs>

                                {this.state.selectedTab === 0 && this.renderArticles()}
                                {this.state.selectedTab === 1 && this.renderArticles()}
                                {this.state.selectedTab === 2 && this.renderArticles()}
                                
                                <Button style={webStyles.seeMoreBtn}>See More</Button>
                            </Box>


                        </Grid>
                    </Grid>
                </Grid>
                <FooterWeb />

            </>
        );
    }
}

const webStyles = {
    leftSectionStyle: {
        background: "#F7F9FA",
        borderRadius: "16px",
        padding: "23px 24px",
    },
    mainHeadingBold: {
        fontSize: "24px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    headingBold: {
        fontSize: "20px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    headingDisplay: {
        display: "flex",
        justifyContent: "space-between"
    },
    subBoldHeading: {
        fontSize: "18px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    subText: {
        fontSize: "18px",
        fontFamily: "Rubik",
    },
    subNormalHeading: {
        fontSize: "14px",
        fontFamily: "Rubik",
        fontWeight: 500,
        color: "#535353"
    },
    imageMargin: {
        marginRight: "18px"
    },
    marginBottomStyle: {
        marginBottom: "38px",
    },
    btnStylesBlue: {
        fontFamily: "Rubik",
        fontSize: "16px",
        background: "#364BA0",
        color: "white",
        padding: "10px",
        borderRadius: "8px",
    },
    btnStyleTans: {
        fontFamily: "Rubik",
        fontSize: "16px",
        background: "#F7F9FA",
        color: "#364BA0",
        padding: "10px",
        borderRadius: "8px",
        border: "1px solid #364BA0",
    },
    chipStyle: {
        background: "#E4F5FF",
        borderRadius: "26px",
        display: "flex",
        padding: "8px",
        gap: "6px",
        justifyContent: "center",
    },
    skillStyle: {
        color: '#0A0A0A',
        padding: "2px 8px",
        fontSize: "16px"
    },
    sub14fontStyle: {
        fontSize: "14px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    sub16fontStyle: {
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
        marginBottom: "16px"
    },
    lightBlueBox: {
        background: "#E4F5FF",
        padding: "24px",
        borderRadius: "12px"
    },
    starIcon: {
        color: '#FFB81F'
    },
    rating: {
        marginLeft: "2px",
        marginRight: "8px"
        , fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "14px",
        color: "#575757"
    },
    seeMoreBtn: {
        border: "1px solid #364BA0",
        fontFamily: "Rubik",
        fontSize: "16px",
        color: "#364BA0",
        width: "100%",
        marginTop: "24px",
        textTransform: "inherit" as "inherit",
    },
    projectBoxes: {
        background: "#EDF9FF",
        padding: "24px",
        borderRadius: "12px"
    },
    educationDateStyle: {
        fontSize: "12px",
        fontFamily: "Inter",
        fontWeight: 550,
        marginTop: "8px",
        marginBottom: "8px",
        fontStyle: "italic",
    }
}