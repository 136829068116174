import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from "../../../framework/src/StorageProvider";
import {getStorageData, setStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  token: string;
  accountId: any;
  showPassword: boolean;
  rememberMe: boolean,
  emailErr: string,
  passwordErr: string,
  role:string;
  isHovered:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: any;
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  loginApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      token: "",
      accountId: null,
      showPassword: false,
      rememberMe: false,
      emailErr: "",
      passwordErr: "",
      role:"",
      isHovered:false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End
  }

  async componentDidMount() {
    // this.callGetValidationApi();
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const rememberedEmail = await getStorageData('rememberedEmail');
    const rememberedPassword = await getStorageData('rememberedPassword');
    if (rememberedEmail && rememberedPassword) {
      this.setState({
        email: rememberedEmail,
        password: rememberedPassword,
      });
    }
    let role = await getStorageData("role")
    this.setState({role:role})
    // Customizable Area End
  }

  // Customizable Area Start
  handleGoToSocialLogin = () => {
    if (this.state.role === 'client') {
      this.props.navigation.navigate("EmailAccountRegistration");
    } else {
      this.props.navigation.navigate("ProfessionalAccountRegistration")
    }
  };
  handleEmailLogIn = (userType:any) => {
    if(userType==="freelancer"){

      this.props.navigation.navigate("CustomForm")
    }
    else if(userType==="customer"){

      this.props.navigation.navigate("ClientHomePage")
    }
  };
  handleForgotPasswordClick = () => {
    const loginMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    loginMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ForgotPassword'
    );
    loginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(loginMessage);
  };
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  rememberMefunction = async () => {
    if (this.state.rememberMe) {
     await  setStorageData('rememberedEmail',this.state.email);
     await  setStorageData('rememberedPassword',this.state.password);
    let data =  await getStorageData('rememberedEmail');
    }
  }

  loginAPI = () => {
    if (this.state.email === "" && this.state.password === "") {
      this.setState({ emailErr: "* Please enter email", passwordErr: configJSON.errorMessage })
    } else if (this.state.email === "") {
      this.setState({ emailErr: "* Please enter email" })
    } else if (this.state.password === "") {
      this.setState({ passwordErr: configJSON.errorMessage  })
    } else {
      const jsonData = {
        data: {
          type: "email_account",
          attributes: {
            email: this.state.email,
            password: this.state.password,
          },
        },
      };
      this.apiEmailLoginCallId = this.apiCall({
        contentType: "application/json",
        method: "POST",
        endPoint: "bx_block_login/logins",
        body: jsonData,
      });
      storage.set('UserEmail',this.state.email)
      storage.set('UserPassword',this.state.password)
    }

  };
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  // Web Event Handling

  handleRememberMeChange = (e: any) => {
    this.setState({ rememberMe: !this.state.rememberMe },() =>{
      this.rememberMefunction()
    });
  }
  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  handleEmailNameChange =(event:any) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!event.target.value.match(emailPattern)) {
      this.setState({
        emailErr: '* Please enter a valid email address',
        email: event.target.value
      });
    } else {
      this.setState({
        emailErr: '',
        email: event.target.value
      });
    }

  };

  passwordErrorMessage = "* Please enter password";
  passwordErrorMessage3= "* Password must not exceed 12 characters"
  passwordErrorMessage4 = "* Password must not contain spaces"

  handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    this.setState({ password: event.target.value, passwordErr: "" });
    if (!newPassword) {
      this.setState({ passwordErr: this.passwordErrorMessage });
    } else if (newPassword.length > 12) {
      this.setState({
        passwordErr: this.passwordErrorMessage3
      });
    } else if (newPassword.includes(" ")) {
      this.setState({
        passwordErr: this.passwordErrorMessage4
      });
    } 
    else {
      this.setState({ passwordErr: "" });
    }
  };
  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  
  handleLoginAPIresponse(responseJson: any) {
    if (responseJson.errors?.length) {
      this.setState({ passwordErr: responseJson.errors[0].failed_login });
    }
    else {
      storage.set("authToken", responseJson?.meta?.token)
      storage.set("accountId", responseJson?.meta?.id);
      storage.set("accountHolderName", responseJson?.meta?.user_name)
      storage.set("userType",responseJson?.meta?.user_type)
      storage.set("is_first_time_login",responseJson?.meta?.is_first_time_login)
      this.setState({
        token: responseJson?.meta?.token,
        accountId: responseJson?.meta?.id
      });
      this.handleEmailLogIn(responseJson?.meta?.user_type);
    }
   

  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
 

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleLoginAPIresponse(responseJson);
    }

    // }

    // Customizable Area End
  }
  // Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
 
  // Customizable Area End
}
