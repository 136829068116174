import React from "react";

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  FormControl,
  Button,
  styled,
  TextField,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactCodeInput from "react-code-input";
export const configJSON = require("./config");
import { customStyles } from "../../../components/src/AppHeader.web";
import Select, { components } from "react-select"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReCAPTCHA from "react-google-recaptcha"
// Customizable Area End

// Customizable Area Start

const CaptchaBox = styled(Box)({
  '& .recaptcha-checkbox': {
    cursor: 'pointer'
  }
})
const CustomSelect = styled(Select)({
    fontSize: "18px",
    fontFamily: "Inter",
    "& .css-13cymwt-control": {
      height: "50px"
    },
    "& ::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#F5F5F5",
      marginLeft: "15px"
    },
    "& ::-webkit-scrollbar-thumb": {
      backgroundColor: "#2684FF",
      borderRadius: "3px",
      height: "50px"
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      backgroundColor: " #2684FF"
    },
    "::-webkit-scrollbar-track:hover": {
      backgroundColor: " #F0F0F0"
    },
    "& .css-1u9des2-indicatorSeparator": {
      display: "none"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "42px",
      color: "black"
    },
    "& .css-qbdosj-Input": {
      gridTemplateColumns: "none",
      margin: "0px",
      paddingBottom: "0px",
      paddingTop: "0px",
    },
    "& .css-1xc3v61-indicatorContainer": {
      padding: "0px",
      color: "black"
    },
    "& .css-b62m3t-container ": {
      height: "60px"
    },
    "& .css-1jqq78o-placeholder": {
      color: "black"
    },
    "& .css-151sz6c-indicatorContainer:hover": {
      color: "black"
    },
    "& .css-151sz6c-indicatorContainer": {
      color: "black"
    },
    "& .css-1xc3v61-indicatorContainer:hover ": {
      color: "black"
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      height: "50px"
    },
    "& .css-1nmdiq5-menu": {
      padding: "10px"
    },
    "& .css-l0mvv6-control": {
      cursor: "pointer"
    },
    "& .css-1okebmr-indicatorSeparator": {
      backgroundColor: "#fff"
    },
    "& .css-2613qy-menu": {
      position: "absolute",
      padding: "20px"
    }
})
const CustomDialog = styled(Dialog)({
  '& .MuiPaper-rounded':{
    borderRadius: "18px",
    width:"480px"
  }
})
import ProfessionalAccountRegistrationController, {
  Props
} from "./ProfessionalAccountRegistrationController.web";
import {
  ellipse,
  login,
  freelancer,
  image_Success
} from "./assets";
import IconButton from '@material-ui/core/IconButton';
import { Horizontalinventohub } from "../../landingpage/src/assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    },
    secondary: {
      main: "#364BA0"
    }
  },
  typography: {
    fontFamily: "Inter"
  }
});

const MainContainer = styled(Box)(({ theme }) => ({
  '@media(max-width: 959px)':{
    '& .dots-img': {
      display: 'none',
      margin: 'auto',
    },
    '& .root-container':{
      paddingLeft:"0px !important",
    }
  },
  '@media(max-width: 1260px)':{
    '& .root-container':{
      paddingLeft:"0px !important",
    }
  },

 }));
const props = {
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '66px',
    borderRadius: '10px',
    fontSize: '30px',
    height:"60px",
    backgroundColor: 'white',
    color: 'gray',
    border: '1px solid gray',
    textAlign: "center" as "center"
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '40px',
    borderRadius: '3px',
    fontSize: '14px',
    height: '26px',
    paddingLeft: '7px',
    backgroundColor: 'black',
    color: 'red',
    border: '1px solid red'
  }
}

const props2 = {
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '45px',
    height: '45px',
    borderRadius: '10px',
    fontSize: '30px',
    backgroundColor: 'white',
    color: 'gray',
    border: '1px solid gray',
    textAlign: "center" as "center"
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield' as "textfield",
    width: '40px',
    borderRadius: '3px',
    fontSize: '14px',
    height: '26px',
    paddingLeft: '7px',
    backgroundColor: 'black',
    color: 'red',
    border: '1px solid red'
  }
}

// Customizable Area End

export class ProfessionalAccountRegistration extends ProfessionalAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDialogBox = () => {
    return (
        <CustomDialog
            open={this.state.isDialogOpen}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={this.handleCloseDialog}
                        aria-label="close"
                        style={{ position: 'absolute', right: 22, top: 12 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            <DialogContent style={{ textAlign: 'center',padding:"24px" }}>
                <img src={image_Success} alt="Success" style={{ width: "35%", borderRadius: "50%",marginTop:"20px" ,marginBottom:"10px"}} />
                <Typography gutterBottom style={webStyle.congratsText}>
                    Congratulations!
                </Typography>
                <Typography style={webStyle.profileCreatedText}>
                Your account has been created successfully.
                </Typography>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                        variant="contained"
                        style={{ marginBottom: '10px',borderRadius:"8px",background:"#364BA0",color: "white" }}
                        onClick={this.handleHome}
                    >
                        COMPLETE MY PROFILE
                    </Button>
                    <Button
                        variant="outlined"
                        style={{borderRadius:"8px",borderColor:"#4A4A4A0",color:"#4A4A4A" }}
                        onClick={this.handleHome}
                    >
                        REMIND ME LATER
                    </Button>
                </Box>
            </DialogContent>
        </CustomDialog>
    );
};

  emailFreelancerError = () => {
    return (
      this.state.emailFreelancerError && (
        <div style={webStyle.formError}>{this.state.emailFreelancerError}</div>
      )
    );
  };
  
  firstNameFreelancerError =()=>{
    return(
      this.state.firstFreelancerNameErr && (
        <div style={webStyle.formError}>
          {this.state.firstFreelancerNameErr}
        </div>
      )
    )
  }
  
  lastNameFreelancerError = ()=>{  
    return(
      this.state.lastFreelancerNameErr && (
        <div style={webStyle.formError}>{this.state.lastFreelancerNameErr}</div>
      ) 
    )
  }

  countryFreelancerError =()=>{
    return(
      this.state.countryFreelancerErr && <span style={webStyle.formError}>
      {this.state.countryFreelancerErr}
      </span>
    )
  }
captchaFreelancerError = ()=>{
return(
  this.state.captchaErr.length>0 && <span style={webStyle.formError}>
  { this.state.captchaErr}
  </span>
)
}

 formStyles = {
    labelStyle: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#535353",
      marginBottom: "12px",
      fontFamily: "Rubik"
    },
    inputStyle: {
      height: "55px",
    marginBottom: "26px",
    "& .MuiFormHelperText-contained": {
      marginLeft: 0
    }
    },
    topSpacing: {
      marginTop: "16px"
    },
    startEndDate: {
      display: "flex",
      justifyContent: "space-between"
    },
  };
    webStyle :any= this.props;
  displayTime() {
    const { seconds } = this.state;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
   CustomDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <ExpandMoreIcon style={{ cursor: 'pointer' }}/>
      </components.DropdownIndicator>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
 
    const {containsUppercase,containsNumber,containsSpecialChar,isTermsAccepted,loading} = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.state.verfication === false &&
        <div style={{position:"relative"}}>
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <CircularProgress /> 
            </div>
          ) : (
        <MainContainer style={{ maxWidth: 1440, padding: 0 }}>
        <div style={webStyle.root} className="root-container">
          <Grid container>
          <Grid item xs={12} md={6}>
            <Box style={{width:"max-content",margin:"auto"}}>
            <img src={Horizontalinventohub} style={webStyle.invento} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
                <Typography style={{...webStyle.Subheading,marginTop:"16px",marginBottom:"24px"}}>SIGN UP</Typography>
                <Box style={webStyle.freelancerTextBox}>
                  <img src={freelancer} alt="freelancer" style={webStyle.freelancerImg}/>
                  <Typography style={webStyle.freelancerText}>IP Professional</Typography>
                </Box>
               <Box style={webStyle.formStyle2}>
                      <Box style={webStyle.boxstyle}>
                        <Typography style={webStyle.labelStyle}>First Name<span style={webStyle.starStyle}>*</span></Typography>
                        <FormControl style={webStyle.textField} variant="outlined">
                          <TextField
                            data-test-id="txtInputFirstName"
                            name="firstName"
                            type="text"
                            variant="outlined"
                            id="txtFirstName"
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "FirstName",
                              onKeyPress: this.handleKeyPress,
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "Rubik",
                                color: "#000000"
                              },
                              maxLength: 36
                            }}
                            value={this.state.firstName}
                            onChange={(event: any) =>
                              this.handleFirstFreelancerNameChange(event)
                            }
                          />
                        </FormControl>
                        {this.firstNameFreelancerError()}
                      </Box>

                      <Box style={webStyle.boxstyle}>
                        <Typography style={webStyle.labelStyle}>Last Name<span style={webStyle.starStyle}>*</span></Typography>
                        <FormControl style={webStyle.textField} variant="outlined">
                          <TextField
                            data-test-id="txtInputLastName"
                            name="lastName"
                            type="text"
                            variant="outlined"
                            id="txtLastName"
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "LastName",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "Rubik",
                                color: "#000000"
                              },
                              onKeyPress: this.handleKeyPress,
                              maxLength: 36
                            }}
                            value={this.state.lastName}
                            onChange={(event: any) =>
                              this.handleLastFreelancerNameChange(event)
                            }
                          />
                        </FormControl>
                        {this.lastNameFreelancerError()}
                      </Box>

                   <Box component={"div"} style={{...webStyle.boxstyle,marginBottom:"4px"}}>
                  <Typography style={webStyle.labelStyle}>
                    Select Country<span style={webStyle.starStyle}>*</span>
                  </Typography>
                  <CustomSelect
                  options={configJSON.countryNames}
                  data-test-id="handlecountry"
                  onChange={(countries: any) => this.handleCountry(countries)}
                  styles={customStyles}
                  components={{ DropdownIndicator: this.CustomDropdownIndicator }}
                  placeholder="Select Country"
                />
                </Box>
                {this.countryFreelancerError()}

                <Box style={{...webStyle.boxstyle,marginTop:"27px"}}>
                  <Typography style={webStyle.labelStyle}>Email<span style={webStyle.starStyle}>*</span></Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <TextField
                      id="txtemail"
                      name="email"
                      type="text"
                      variant="outlined"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "email",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000"
                        },
                        maxLength: 50
                      }}
                      value={this.state.email}
                      onChange={(event: any) =>
                        this.handleFreelancerEmailNameChange(event)
                      }
                    />
                  </FormControl>
                 { this.emailFreelancerError()}
                </Box>
                
                <Box style={webStyle.boxstyle}>
                <Typography style={webStyle.labelStyle}>
                    Password<span style={webStyle.starStyle}>*</span>
                </Typography>
                <FormControl style={webStyle.textField} variant="outlined">
                  <OutlinedInput
                    onFocus={() => this.setState({ isTyping: true })}
                    onBlur={() => this.setState({ isTyping: false })}
                    onChange={this.handlePassword}
                    value={this.state.password}
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    id="outlined-adornment-password"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "password",
                      style: {
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "Rubik",
                        color: "#000000"
                      }
                    }}
                    onPaste={(e) => e.preventDefault()}  // Prevent password pasting
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility}
                        edge="end"
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                </FormControl>

                  {this.state.passwordFreelancerErr && (
                    <div style={webStyle.formError}>{this.state.passwordFreelancerErr}</div>
                  )}
                </Box>
                <div>
                  {this.state.isTyping && !this.state.isPasswordValid && (
                    <p style={webStyle.passwordStyle as React.CSSProperties}>
                      Seems good, we suggest to make it stronger
                    </p>
                  )}
                  <Typography style={{ fontFamily: "Rubik" }}>
                    <GreenCheckbox
                      size="medium"
                      checked={containsUppercase}
                      name="containsUppercase"
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                    Contain at least one uppercase letter
                  </Typography>
                  <Typography style={{ fontFamily: "Rubik" }}>
                    <GreenCheckbox
                      size="medium"
                      checked={containsNumber}
                      name="containsNumber"
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                    Contain at least one number
                  </Typography>
                  <Typography style={{ fontFamily: "Rubik" }}>
                    <GreenCheckbox
                      size="medium"
                      checked={containsSpecialChar}
                      name="containsSpecialChar"
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                    Contain at least one special character
                  </Typography>
                </div>

        
               
              
                <Box style={webStyle.boxstyle}>
                <CaptchaBox style={webStyle.recaptchaStyle}>
                  <ReCAPTCHA 
                   sitekey={this.state.sitekey}
                   id = "handleSelectMainBoxClick"
                   onChange={(value: any) => {
                    this.handleCaptchaValue(value)
                  }}
                  />
                  {this.captchaFreelancerError()}
                  </CaptchaBox>
                </Box>

                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isTermsAccepted}
                        onChange={this.handleTermsCheckboxChange}
                        data-test-id="termsCheckbox"
                      />
                    }
                    label={
                      <span style={webStyle.linkLabelStyle}>
                        I accept the{" "}
                        <a href="/terms-and-conditions" target="_blank" style={{ ...webStyle.linkStyle, fontWeight: "bold" }}>
                          Terms and Conditions{" "}
                        </a>
                        and{" "}
                        <a href="/privacy-policy" target="_blank" style={{ ...webStyle.linkStyle, fontWeight: "bold" }}>
                          Privacy Policy
                        </a>
                      </span>
                    }
                  />
                </Box>
                
                <Button
                 type="submit"
                 variant="contained"
                 style={{
                  ...webStyle.signinButton,
                  backgroundColor: !isTermsAccepted ? '#d3d3d3' : webStyle.signinButton.backgroundColor,
                  color: !isTermsAccepted ? '#a9a9a9' : webStyle.signinButton.color,
                }}
                onClick={this.handleFreelancerSignUp}
                disabled={!isTermsAccepted}
                >
                 SIGN UP
                </Button>

                <Typography style={webStyle.subHeading}>
                  Have an account?{" "}
                  <span style={webStyle.signUpLink} onClick={this.handleSignIn}>
                   SIGN IN 
                  </span>
                </Typography>
              </Box>
            </Box>
            </Grid>
            <Grid item xs={12} md={6}  className="dots-img" >
              <img src={login} style={webStyle.image} alt="peopleImg"/>
            </Grid>
          </Grid>
        </div>
       <img src={ellipse} alt="ellipse" style={webStyle.ellipseImg}/>
        </MainContainer>
      )}
      </div>
        }
        {this.state.verfication === true && !this.state.successPage &&(
          <>
          {loading ? (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <CircularProgress /> 
              </div>
            ) : (
            <Grid container spacing={0}>
              <Grid container item xs={12} md={5} justifyContent="center">
                <Grid item xs={9}>
            <img src={Horizontalinventohub} style={webStyle.invento} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
                  <div style={webStyle.verifyStyle}>
                    <Typography style={webStyle.otpStyle}>OTP Verification</Typography>
                    <Typography style={webStyle.otpTime}>Enter 6 Digit verification code sent to your email ID  - {this.state.email}</Typography>
                    <div>
                    <ReactCodeInputStyle
                      {...props}
                      type="number"
                      fields={6}
                      onChange={this.handleOtpValue}
                      value={this.state.otpValue} name={"VerifyOtp"} inputMode={"tel"} />
                        <ReactCodeInputStyle2
                      {...props2}
                      type="number"
                      fields={6}
                      onChange={this.handleOtpValue}
                      value={this.state.otpValue} name={"VerifyOtp"} inputMode={"tel"} />
                      </div>
                      
                      <div style={{display:"flex",justifyContent:"space-between",marginTop:"18px",marginRight:"10px",marginBottom:"18px"}}> 
                    <Typography style={webStyle.otpTime}>Enter Verification Code:{this.displayTime()}</Typography>
                    <span style={webStyle.ResendStyle} onClick={this.handleResendOtp} 
                      data-testid="resend_emailotp">Resend Code</span>
                      </div>

                    <Box component={"div"} style={webStyle.topSpacing}>
                      <FormControl style={{ width: '98%' }}>
                        <Button
                          style={{...webStyle.nxtBtn, marginBottom: '10px',backgroundColor: "#364BA0",color: 'white'}}
                          variant="contained"
                          disabled={this.state.VerificationError}
                          onClick={this.handleVerifybox}
                        >
                          VERIFY
                        </Button>
                      </FormControl>
                    </Box>
                    {this.state.otpError && <Typography style={webStyle.timer}>{this.state.otpError}</Typography>}
                    <Box component={"div"} margin={"30px 0"}>
                      <span style={webStyle.haveAccount}>Have an account? </span>
                      <span style={webStyle.signIn} onClick={this.handleSignIn}>SIGN IN</span>
                    </Box>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <img src={login} style={webStyle.image} alt="peopleImg" />
              </Grid>
            </Grid>
            )}
          </>
        )
        }
        {this.state.isDialogOpen && <>
          {this.renderDialogBox()}
        </>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const GreenCheckbox = withStyles({
  root: {
    color: "#535353",
    "&$checked": {
      color: "#33A1E5"
    }
  },
  checked: {}
})(Checkbox);
const GreenCheckbox1 = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);
const 
ReactCodeInputStyle=styled(ReactCodeInput)({
  "@media (max-width: 1305px)": {
display:"none !important" 
  }
})
const 
ReactCodeInputStyle2=styled(ReactCodeInput)({
  display:"none !important" ,
  "@media (max-width: 1305px)": {
  display:"inline-block !important" 

  }
})
const webStyle = {
  freelancerTextBox:{
    display: "flex" as "flex",
    gap: "14px",
    background: "#364BA0",
    width: "205px",
    borderRadius: "8px",
    padding: "10px",
    justifyContent: "center" as  "center",
    marginTop:"14px",
    marginBottom:"51px"
  },
  freelancerImg:{
    width:"10px"
  },
  freelancerText:{
    fontSize: 20,
    fontFamily: "Rubik",
    color: "#FFFFFF",
    fontWeight:600
  },
  starStyle:{
    fontWeight: 800,
    color: "#FF5E5B",
},
congratsText:
{
  marginTop:"10px",
  fontSize: "22px",
color: "#059669",
fontWeight: 600,
fontFamily: "Rubik"
},
profileCreatedText:
{
   marginBottom: '20px',
   color:"#011342",
  fontSize: "14px",
  fontWeight: 500,
  marginTop: "18px",
  fontFamily: "Rubik"
},
  formStyle2: {
    maxWidth: "432px",
  },
  linkLabelStyle:{
    fontFamily: "Rubik",fontSize: "14px", color: "#333"
  },
  linkStyle:{
    textDecoration: 'underline' as 'underline',
    color: '#1976d2',
    fontFamily: "Rubik",
    cursor:"pointer",
    fontWeight:"regular",
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important"
    }
  },
  formError: {
    color: "red",
    fontSize: 14,
    fontFamily: "Rubik",
    marginTop:"4px"
  },
  passwordStyle: {
    color: "#000000",
    fontWeight: "bold",
    fontFamily: "Rubik",
    marginTop: "4px",
    fontSize: "16px",
    textAlign: "left"
  },
  recaptchaStyle:{
    marginTop:"1rem"
  },
  signinButton: {
    marginBottom: "24px",
    marginTop:"10px",
    height: "55px",
    backgroundColor: "#364BA0",
    color: "#FFFFFF",
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Rubik",
    borderRadius:"10px"
  },
  subHeading: {
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "31px",
    color: "#6F6E6E",
    textDecoration: "none",
    fontFamily: "Rubik"
  },
  signUpLink: {
    color: "#364BA0",
    cursor: "pointer",
    textDecoration: 'underline'
  },
  root: {
    flexGrow: 1,
    margin: "0 auto",
    maxWidth: 1440,
    "@media (max-width: 960px)": {
      paddingLeft: "0"
    }
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    width:'370px',
    left:0,
    zIndex: -1
  },
  Subheading: {
    fontSize: "32px",
    fontWeight: 500,
    color: "#364BA0",
    fontFamily: "Rubik"
  },
  image: {
    width: "50%",
    height: "auto",
    position:"absolute" as "absolute",
    right:0,
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  labelStyle: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#535353",
    marginBottom: "12px",
    fontFamily: "Rubik",
    marginTop: "10px",
    cursor:"pointer"
  },
  inputStyle: {
    height: "55px",
    marginBottom: "26px",
    "& .MuiFormHelperText-contained": {
      marginLeft: 0
    }
  },
  passwordInputStyle: {
    height: "55px",
    "& .MuiFormHelperText-contained": {
      marginLeft: 0
    }
  },
  haveAccount: {
    color: "#6F6E6E",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "40px"
  },
  signIn: {
    color: "#364BA0",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "40px",
    textDecoration: "underline",
    cursor: "pointer"
  },
  freelancerBox: {
    borderRadius: "10px",
    background: "#206FC4",
    height: "60px",
    width: "206px"
  },
  firmBox: {
    borderRadius: "10px",
    background: "#E8F1F8",
    height: "60px",
    width: "204px",
    "@media (max-width: 768px)": {
      marginTop: "12px"
    }
  },
  boxStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: "10px",
    marginBottom: "12px",
    "@media (max-width: 768px)": {
      display: "block"
    }
  },
  frameStyle: {
    position: "relative" as "relative",
    left: "90%",
    top: "6px"
  },
  boxInnerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  ipFirm: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600
  },
  freelanceText: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600
  },
  imgRight: {
    marginRight: "6px"
  },
  selectMainBox: {
    minHeight: "52px",
    border: "1px solid silver",
    marginBottom: "3px",
    borderRadius: "4px"
  },
  selectBox: {
    padding: "12px",
    width: 451,
    borderRadius: 15,
    background: "#4A4A4A"
  },
  labelStyle3: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Rubik"
  },
  topSpacing: {
    marginTop: "16px"
  },
  addEducation: {
    padding: "14px",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize"
  },
  addEducationTop: {
    marginTop: "22px"
  },
  startEndDate: {
    display: "flex",
    justifyContent: "space-between"
  },
  uploadBtn: {
    backgroundColor: "#E9F0F9",
    border: "1px dashed #216FC4",
    padding: '30px',
    borderRadius: '16px',
    height: "150px",
    "&:hover": {
      backgroundColor: "#E9F0F9",
    }

  },
  maxStyle: {
    textAlign: "right" as "right",
    color: 'red',
    textTransform: 'capitalize' as 'capitalize',
    fontFamily: "Rubik"
  },
  imgCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  verifyStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  nxtBtn: {
    padding: "12px", borderRadius: "8px"
  },
  otpStyle: {
    marginTop: "80px",
    fontSize: "32px",
    fontWeight: 700,
    color: "#364BA0",
    fontFamily: "Rubik"
  },
  boxstyle: {
    marginBottom: "27px",
    cursor:"pointer"
  },
  backNextBtn: {
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
    marginTop: "84px",
    "@media (min-width: 425px) and (max-width:759px)": {
      marginTop: "20px",
      position: "unset"
    }
  },
  suceesText: {
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    marginTop: "40px",
    textAlign: "center" as "center"
  },
  successImage: {
    marginTop: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  position: {
    position: "absolute" as "absolute"
  },
  county: {
    width: "100%",
    height: 60,
    position: "relative" as "relative",
    "& ::-webkit-scrollbar": {
      width: 5,
      borderRadius: 10,
      marginLeft: 10
    },
    "& ::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: 10
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#206FC4",
      borderRadius: 10,
      height: 50
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#555",
      borderRadius: 10
    },
    "& .MuiAccordion-root": {
      zIndex: 1,
      borderRadius: 6
    },
    "& .MuiAccordion-rounded": {
      boxShadow: "none",
      border: "1px solid #CBCBCB"
    },
    "& .MuiIconButton-root ": {
      padding: "7px"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "45px"
    }
  },
  borderAccordian: {
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px solid #CBCBCB"
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 50
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "15px 0"
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 8px 16px 16px"
    }
  },
  countryName: {
    fontFamily: "Inter",
    padding: 5,
    cursor: "pointer",
    "&:hover": {
      background: "#206FC4",
      color: "#fff",
      borderRadius: 6,
      marginRight: 10
    }
  },
  mainDiv: {
    "@media (max-width: 1440px)": {
      paddingLeft: "100"
    }
  },
  facebookBtn: {
    padding: "12px",
    fontWeight: 600,
    borderRadius: "8px"
  },
  uploadTxt: {
    textTransform: 'capitalize' as 'capitalize',
  },
  facebookText: {
    marginLeft: "10px"
  },
  errorMsg: {
    color: "red",
    fontFamily: "Rubik",
    height: "50px",
    fontSize:"12px",
  },
  timer: {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "red",
    marginTop: "10px"
  },
  otpTime: {
    marginBottom: "20px",
    color: "#535353",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Rubik",
  },
  ResendStyle:{
    color: "#364BA0",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer"
  },
  seemsText: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    marginTop: "4px",
  },
  invento:{
    width:"264px",
    height:"48px",
    objectFit:"cover" as "cover",
     marginTop: "42px",
     marginLeft:"10px",
     marginBottom: "34px",
     cursor: "pointer",
  }
};

export default ProfessionalAccountRegistration
// Customizable Area End
