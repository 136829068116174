import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTyping: boolean;
  isPasswordValid: boolean;
  emailFreelancerError:string
  firstFreelancerNameErr:string
  lastFreelancerNameErr:string
  passwordFreelancerErr:string
  countryFreelancerErr:string
  isTermsAccepted: boolean
  showPassword:boolean
  password: string;
  containsUppercase: boolean;
  containsNumber: boolean;
  containsSpecialChar: boolean;
  country: any;
  email: any;
  lastName: string;
  firstName: string;
  activeStep: number;
  isValidEmail: boolean;
  education: string;
  passingYear: any;
  collegeName: string;
  AddcollegeName: string;
  Addeducation: string;
  AddpassingYear: string;
  verfication: boolean;
  experience: string;
  companyName: string;
  educationError: boolean;
  passingYearError: boolean;
  collegeNameError: boolean;
  additionalFieldsCount: any;
  additionalExperience: any;
  experienceError: boolean
  companyNameError: boolean;
  startDate: string,
  endDate: string,
  startDateError: boolean;
  endDateError: boolean;
  additionalTextBoxValue: string;
  additionalTextBoxVisible: boolean;
  successPage: boolean;
  open: boolean;
  degreeFile: null;
  uploadeddegreeFileName: string;
  PassportFile: null;
  uploadedPassportName: string;
  AadharFile: null;
  uploadedAadharName: string;
  fileError: string;
  adharError: string;
  addEdu: any;
  passportError: string
  file2Error: boolean;
  adhar2Error: boolean;
  passport2Error: boolean;
  searchInput: string;
  seconds: number;
  otpValue: any;
  authToken: any;
  emailValid: string;
  timerExpired: boolean;
  VerificationError: boolean;
  otpError: string;
  sitekey: string;
  captchaErr: string;
  EducationDetails: any;
  ExperienceDetails: any;
  isSnackbarOpen: boolean
  errors: any;
  errors2: any;
  newEntry2: any;
  newEntry: any
  captchaDone: any
  disabledResendOtp: any;
  otpRemainingTime: any;
  temp: any;
  navigateToPath: any;
  isDialogOpen: boolean
  loading:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfessionalAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSignUpCallId: any
  apiEmailOtpCallId: any
  myInterval: any;
  apiOtpVerifactionCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isTyping: false,
      isPasswordValid: false,
      emailFreelancerError:"",
      firstFreelancerNameErr:"",
      lastFreelancerNameErr:"",
      passwordFreelancerErr:"",
      countryFreelancerErr:"",
      isTermsAccepted: false,
      showPassword: false,
      password: "",
      containsUppercase: false,
      additionalFieldsCount: 0,
      containsNumber: false,
      containsSpecialChar: false,
      country: "",
      email: "",
      addEdu: "",
      isSnackbarOpen: false,
      lastName: "",
      EducationDetails: [
        {
          educationDetails: "",
          yearOfPassing: "",
          nameOfCollege: "",
        },
      ],
      ExperienceDetails: [
        {
          totalExperience: "",
          companyName: "",
          startDate: "",
          endDate: ""
        },
      ],
      errors: [
        {
          educationDetailsError: false,
          yearOfPassingError: false,
          nameOfCollegeError: false,
        },
      ],
      errors2: [
        {
          totalExperienceError: false,
          companyNameError: false,
          startDateError: false,
          endDateError: false
        },
      ],
      newEntry: {
        educationDetails: "",
        yearOfPassing: "",
        nameOfCollege: "",
      },
      newEntry2: {
        totalExperience: "",
        companyName: "",
        startDate: "",
        endDate: ""
      },
      firstName: "",
      activeStep: 0,
      isValidEmail: true,
      education: "",
      passingYear: null,
      collegeName: "",
      AddcollegeName: "",
      Addeducation: "",
      AddpassingYear: "",
      verfication: false,
      experience: "",
      companyName: "",
      startDate: '',
      endDate: '',
      educationError: false,
      passingYearError: false,
      collegeNameError: false,
      experienceError: false,
      companyNameError: false,
      startDateError: false,
      endDateError: false,
      additionalExperience: "",
      additionalTextBoxValue: "",
      additionalTextBoxVisible: false,
      successPage: false,
      open: false,
      degreeFile: null,
      uploadeddegreeFileName: "",
      PassportFile: null,
      uploadedPassportName: "",
      AadharFile: null,
      uploadedAadharName: "",
      fileError: "",
      adharError: "",
      passportError: "",
      file2Error: false,
      adhar2Error: false,
      passport2Error: false,
      searchInput: "",
      seconds: 60,
      otpValue: '',
      authToken: '',
      emailValid: "",
      timerExpired: false,
      VerificationError: true,
      otpError: "",
      captchaErr: "",
      captchaDone: "",
      sitekey: "6LcB2F8pAAAAAOc5aiGNwLr0e3_8ZuqkZLwCDocB",
      disabledResendOtp: true,
      otpRemainingTime: 60,
      temp: "",
      navigateToPath: "",
      isDialogOpen: false,
      loading:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  validateForm = () => {
    const { 
      email, 
      firstName, 
      lastName, 
      password, 
      country, 
      containsUppercase,
      containsNumber,
      containsSpecialChar 
    } = this.state;
    
    let isValid = true;
  
    if (!email) {
      this.setState({ emailFreelancerError: "* Email is required" });
      isValid = false;
    } else {
      this.setState({ emailFreelancerError: "" });
    }
  
    if (!firstName) {
      this.setState({ firstFreelancerNameErr: "* First name is required" });
      isValid = false;
    } else {
      this.setState({ firstFreelancerNameErr: "" });
    }
  
    if (!lastName) {
      this.setState({ lastFreelancerNameErr: "* Last name is required" });
      isValid = false;
    } else {
      this.setState({ lastFreelancerNameErr: "" });
    }
  
    if (!password) {
      this.setState({ passwordFreelancerErr: configJSON.requiredPassword });
      isValid = false;
    } else if (
      !containsUppercase ||
      !containsNumber ||
      !containsSpecialChar
    ) {
      this.setState({ passwordFreelancerErr: configJSON.passwordRequirements });
      isValid = false;
    } else {
      this.setState({ passwordFreelancerErr: "" });
    }
  
    if (!country) {
      this.setState({ countryFreelancerErr: "* Country is required" });
      isValid = false;
    } else {
      this.setState({ countryFreelancerErr: "" });
    }
  
    if (this.state.captchaDone.length === 0) {
      this.setState({ captchaErr: "* CAPTCHA is required" });
      isValid = false;
    } else {
      this.setState({ captchaErr: "" });
    }
  
    return isValid;
  };

  handleFreelancerSignUp = () => {
    if (this.validateForm()) {
      this.setState({
        emailFreelancerError: "",
        firstFreelancerNameErr: "",
        lastFreelancerNameErr: "",
        passwordFreelancerErr: "",
        countryFreelancerErr: "",
        captchaErr: "",
      });
  
      this.signUpApi();
    }
  };
  
  handleFreelancerEmailNameChange = (event: any) => {
    const { value } = event.target;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    const sanitizedValue = value.replace(/^\s*/, '');
  
    if (!sanitizedValue.match(emailPattern)) {
      this.setState({
        emailFreelancerError: "* Please enter a valid email address",
        email: sanitizedValue
      });
    } else {
      this.setState({
        emailFreelancerError: "",
        email: sanitizedValue
      });
    }
  };

  handleFirstFreelancerNameChange = (event: any) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/^\s*/, '');
    this.setState({
      firstName: sanitizedValue,
      firstFreelancerNameErr: ""
    });
  };


  handleLastFreelancerNameChange = (event: any) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/^\s*/, '');
    this.setState({
      lastName: sanitizedValue,
      lastFreelancerNameErr: ""
    });
  };

  passwordErrorMessage = "* Please Enter password";
  passwordErrorMessage3 = "* Password must not exceed 12 characters";
  passwordErrorMessage4 = "* Password must not contain spaces";
  passwordErrorMessage5 = "* Password must be at least 4 characters long"

  handlePassword = (event: any) => {
    const newPassword = event.target.value;
    
    let passwordErrorMessage = "";
  
    if (!newPassword) {
      passwordErrorMessage = this.passwordErrorMessage;
    } else if (newPassword.length < 4) {
      passwordErrorMessage = this.passwordErrorMessage5;
    } else if (newPassword.length > 12) {
      passwordErrorMessage = this.passwordErrorMessage3;
    } else if (newPassword.includes(" ")) {
      passwordErrorMessage = this.passwordErrorMessage4;
    }
  
    this.setState({ 
      passwordFreelancerErr: passwordErrorMessage,
      password: newPassword
    });
  
    if (!passwordErrorMessage) {
      this.checkPasswordStrength(newPassword);
    } else {
      this.setState({
        containsUppercase: false,
        containsNumber: false,
        containsSpecialChar: false
      });
    }
  };
  

  checkPasswordStrength = (password: string) => {
    const containsUppercase = /[A-Z]/.test(password);
    const containsNumber = /\d/.test(password);
    const containsSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    );

    this.setState({
      containsUppercase,
      containsNumber,
      containsSpecialChar
    });
  };
  handleTermsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isTermsAccepted: event.target.checked });
  };
  handleSignIn = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  togglePasswordVisibility = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleKeyPress = (event: any) => {
    const charCode = event.charCode;
    if (
      (charCode >= 65 && charCode <= 90) ||   // A-Z
      (charCode >= 97 && charCode <= 122) ||  // a-z
      (charCode >= 48 && charCode <= 57) ||   // 0-9
      charCode === 32                         // space
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };

  handleResendOtp = () => {
    this.setState({ disabledResendOtp: true, timerExpired: false });
    this.emailOtp();
    const countdown = setInterval(() => {
      const { seconds } = this.state;

      if (seconds === 0) {
        clearInterval(countdown);
        this.setState({ disabledResendOtp: false, seconds: 60 });
      } else {
        this.setState({ seconds: seconds - 1 });
      }
    }, 1000); 
  };

  handleLogoClick = () => {
    this.props.navigation.navigate("LandingPage");
  }

  handleCountry = (countries:any) => {
    this.setState({country:countries,countryFreelancerErr:""})
  }
  handleVerifybox = () => {
    if (this.state.otpValue === "") {
      this.setState({ VerificationError: true })
    } else {
      this.otpVerification()
    }
  }
  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };
  handleOtpValue = (otpPin: any) => {
    if (otpPin && otpPin.length === 6) {
      this.setState({ otpValue: otpPin, VerificationError: false, otpError: "" });
    } else {
      this.setState({ VerificationError: true });
    }
  }

  handleBack = () => {
    this.setState({ verfication: true, successPage: false, seconds: 60, otpError: "" })
  }

  handleHome = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleCaptchaValue = (value: string) => {
    if (value.length === 0) {
      this.setState({ captchaDone: value, captchaErr: "* Please complete the ReCAPTCHA" });
    } else {
      this.setState({ captchaDone: value, captchaErr: "" });
    }
  };

  emailOtp = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json"
    };

    let emailData = {
      "data": {
        "attributes": {
          "email": this.state.email
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostEmailOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(emailData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  otpVerification = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json"
    };

    let OtpData = {
      "data": {
        "token": this.state.authToken,
        "otp_code": this.state.otpValue
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiOtpVerifactionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerifyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(OtpData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  signUpApi = () => {
    this.setState({ loading: true });
    const header = {
      redirect: 'follow'
    };

    let formdata = new FormData();
    formdata.append("[data][attributes][email]", this.state.email);
    formdata.append("[data][attributes][first_name]", this.state.firstName);
    formdata.append("[data][attributes][last_name]", this.state.lastName);
    formdata.append("[data][attributes][password]", this.state.password);
    formdata.append("[data][attributes][role_id]", "freelancer");
    formdata.append("[data][attributes][country]", this.state.country.value);
    formdata.append("[data][type]","email_account")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSignUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostSignUpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  handleSignUpApi = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ emailValid: responseJson.errors[0].account })
    } else {
      this.emailOtp()
    }
  }

  handleEmailOtpApi = (responseJson: any) => {
    if (responseJson) {
      this.setState({ authToken: responseJson?.meta?.token, verfication: true });
      this.myInterval = setInterval(() => {
        const { seconds } = this.state;
        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1
          }));
        } else {
          clearInterval(this.myInterval);
          this.setState({ timerExpired: true, disabledResendOtp: false, seconds: 60 })
        }
      }, 1000);
    }
  }

  handleOtpVerifyApi = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ otpError: responseJson.errors[0].otp })
    } else {
      this.setState({ isDialogOpen: true })
    }
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(form: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });
      if (responseJson && responseJson.errors) {
        const errorMessage = responseJson.errors[0]?.account;
        if (errorMessage) {
          this.setState({
            emailFreelancerError: errorMessage,
          });
          return; 
        }
      }
  
      if (apiRequestCallId === this.apiSignUpCallId) {
        this.handleSignUpApi(responseJson);
      } 
      else if (apiRequestCallId === this.apiEmailOtpCallId) {
        this.handleEmailOtpApi(responseJson);
      }
      else if (apiRequestCallId === this.apiOtpVerifactionCallId) {
        this.handleOtpVerifyApi(responseJson);
      }
    } else {
      runEngine.debugLog("GOIT");
    }
    // Customizable Area End
  }
  // Customizable Area End
}
