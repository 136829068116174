import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Box,
  Button,
  Checkbox,
  OutlinedInput,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Accordion,
  ClickAwayListener,
  styled
} from "@material-ui/core";
import { login,ellipse } from "./assets";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { withStyles, createTheme } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Select, { components } from "react-select"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
export const configJSON = require("./config");
import ReCAPTCHA from "react-google-recaptcha"

// Customizable Area End
// Customizable Area Start

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { Horizontalinventohub } from "../../landingpage/src/assets";
import { customStyles } from "../../../components/src/AppHeader.web";
import { relative } from "path";

interface SignUpState {
  isTyping: false,
  isPasswordValid: false,
  country: string;
  job: string;
  showJobs: boolean;
  selectedJob: any;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  phone: string;
  countryCodeSelected: string;
  showPassword: boolean;
  showCountryDropdown: boolean;
  setFieldValue: string;
  containsUppercase: boolean;
  containsNumber: boolean;
  containsSpecialChar: boolean;
  errors: {};
  selectedCountryIndex: -1;
  additionalTextBoxVisible: boolean;
  additionalTextBoxValue: string;
  emailError: string;
  firstNameErr: string;
  lastNameErr: string;
  passwordErr: string;
  countryErr: string;
  jobErr: string;
  listJobs: any[];
  openCountry:boolean;
  countryError:boolean;
  sitekey:any
  captchaDone: any
  captchaErr:string
}
export  class EmailAccountRegistration extends EmailAccountRegistrationController {
  state: SignUpState = {
    isTyping: false,
    isPasswordValid: false,
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    job: "",
    showJobs: false,
    selectedJob: [],
    otpAuthToken: "",
    reTypePassword: "",
    data: null,
    passwordHelperText: "",
    enablePasswordField: false,
    enableReTypePasswordField: false,
    phone: "",
    countryCodeSelected: "",
    showPassword: false,
    showCountryDropdown: false,
    setFieldValue: "",
    password: "",
    containsUppercase: false,
    containsNumber: false,
    containsSpecialChar: false,
    errors: {},
    selectedCountryIndex: -1,
    additionalTextBoxVisible: false,
    emailError: "",
    additionalTextBoxValue: "",
    lastNameErr: "",
    passwordErr: "",
    firstNameErr: "",
    jobErr: "",
    countryErr: "",
    captchaErr:"",
    listJobs: [
      { name: "IP Lawyer" },
      { name: "IP Enforcement" },
      { name: "Patent Holder" },
      { name: "Prototype Developer" },
      { name: "Patent Illustrator" },
      { name: "Patent Liscening Expert" },
      { name: "Patent Database" },
      { name: "Prototype Agent" },
      {name:"Inventors"},
      { name: "Govt.,ngos, msme, companies" },
      { name: 'IP Valuation'},
      { name: "Others" },
      
    ],
    openCountry:false,
    countryError:false,
  sitekey:"6LcB2F8pAAAAAOc5aiGNwLr0e3_8ZuqkZLwCDocB",
  captchaDone:""

  };
  emailError = () => {
    return (
      this.state.emailError && (
        <div style={styles.formError}>{this.state.emailError}</div>
      )
    );
  };

  firstNameError =()=>{
    return(
      this.state.firstNameErr && (
        <div style={styles.formError}>
          {this.state.firstNameErr}
        </div>
      )
    )
  }

  lastNameError = ()=>{  
    return(
      this.state.lastNameErr && (
        <div style={styles.formError}>{this.state.lastNameErr}</div>
      ) 
    )
  }
  jobError = ()=>{
    return(
      this.state.jobErr && (
       <div style={styles.formError}>{this.state.jobErr}</div>
      )
    )
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      isTyping: false,
      isPasswordValid: false,
      country: "",
      job: "",
      showJobs: false,
      selectedJob: [],
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      otpAuthToken: "",
      reTypePassword: "",
      data: null,
      passwordHelperText: "",
      enablePasswordField: false,
      enableReTypePasswordField: false,
      phone: "",
      countryCodeSelected: "",
      showPassword: false,
      showCountryDropdown: false,
      setFieldValue: "",
      containsUppercase: false,
      containsNumber: false,
      containsSpecialChar: false,
      errors: {},
      selectedCountryIndex: -1,
      additionalTextBoxVisible: false,
      firstNameErr: "",
      additionalTextBoxValue: "",
      lastNameErr: "",
      emailError: "",
      jobErr: "",
      countryErr: "",
      passwordErr: "",
      captchaErr:"",
      listJobs: [
        { name: "IP Lawyer" },
        { name: "IP Enforcement" },
        { name: "Patent Holder" },
        { name: "Prototype Developer" },
        { name: "Patent Illustrator" },
        { name: "Patent Liscening Expert" },
        { name: "Patent Database" },
        { name: "Prototype Agent" },
        { name: 'Inventors' },
        { name: "Govt.,ngos, msme, companies" },
        { name: 'IP Valuation'},       
        { name: "Others" },
        
      ],
      openCountry:false,
      countryError:false,
  sitekey:"6LcB2F8pAAAAAOc5aiGNwLr0e3_8ZuqkZLwCDocB",
  captchaDone:""
    };
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {classes} = this.props
    const heading = "Sign up ";

    const {
      containsUppercase,
      containsNumber,
      containsSpecialChar,
      showPassword
    } = this.state;

    const MainContainer = styled(Box)(({ theme }) => ({
      '@media(max-width: 959px)':{
        '& .dots-img': {
          display: 'none',
          margin: 'auto',
        },
        '& .root-container':{
          paddingLeft:"0px !important",
        }
      },
      '@media(max-width: 1260px)':{
        '& .root-container':{
          paddingLeft:"0px !important",
        }
      },
    
     }));
    const CustomDropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <ExpandMoreIcon style={{ cursor: 'pointer' }} />
        </components.DropdownIndicator>
      );
    };

    return (
      <div style={{position:"relative"}}>
      <MainContainer style={{ maxWidth: 1440, padding: 0 }}>
        <div style={styles.root} className="root-container">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box style={{width:"max-content",margin:"auto"}}>
            <img src={Horizontalinventohub} style={styles.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
              <Typography style={styles.headingSign}>SIGN UP</Typography>
              <Box style={styles.formStyle2}>
                <Box style={styles.boxstyle}>
                  <Typography style={styles.labelStyle}>Email<span style={styles.starStyle}>*</span></Typography>
                  <FormControl style={styles.textField} variant="outlined">
                    <TextField
                      id="txtemail"
                      name="email"
                      type="text"
                      variant="outlined"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "email",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000"
                        },
                        maxLength: 36
                      }}
                      value={this.state.email}
                      onChange={(event: any) =>
                        this.handleEmailNameChange(event)
                      }
                    />
                  </FormControl>
                 { this.emailError()}
                </Box>
                <Box style={styles.boxstyle}>
                  <Typography style={styles.labelStyle}>First Name<span style={styles.starStyle}>*</span></Typography>
                  <FormControl style={styles.textField} variant="outlined">
                    <TextField
                      data-test-id="txtInputFirstName"
                      name="firstName"
                      type="text"
                      variant="outlined"
                      id="txtFirstName"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "FirstName",
                        onKeyPress: this.handleKeyPress,
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000"
                        },
                        maxLength: 36
                      }}
                      value={this.state.firstName}
                      onChange={(event: any) =>
                        this.handleFirstNameChange(event)
                      }
                    />
                  </FormControl>
                  {this.firstNameError()}
                </Box>
                <Box style={styles.boxstyle}>
                  <Typography style={styles.labelStyle}>Last Name<span style={styles.starStyle}>*</span></Typography>
                  <FormControl style={styles.textField} variant="outlined">
                    <TextField
                      data-test-id="txtInputLastName"
                      name="lastName"
                      type="text"
                      variant="outlined"
                      id="txtLastName"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "LastName",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000"
                        },
                        onKeyPress: this.handleKeyPress,
                        maxLength: 36
                      }}
                      value={this.state.lastName}
                      onChange={(event: any) =>
                        this.handleLastNameChange(event)
                      }
                    />
                  </FormControl>
                  {this.lastNameError()}
                </Box>
                <Box style={styles.boxstyle}>
                  <Typography style={styles.labelStyle}>Password<span style={styles.starStyle}>*</span></Typography>
                  <FormControl style={styles.textField} variant="outlined">
                    <OutlinedInput
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange2}
                      data-test-id="txtInputLastName"
                      name="password"
                      value={this.state.password}
                      type={showPassword ? "text" : "password"}
                      id="outlined-adornment-password"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "password",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000"
                        }
                      }}
                      onPaste={(e) => e.preventDefault()} 
                      endAdornment={
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      }
                    />
                  </FormControl>
                  {this.state.passwordErr && (
                    <div style={styles.formError}>{this.state.passwordErr}</div>
                  )}
                </Box>
                <div>
                {this.state.isTyping && !this.state.isPasswordValid && (
                    <p style={styles.passwordStyle as React.CSSProperties}>
                      Seems good, we suggest to make it stronger
                    </p>
                  )}
                  <Typography style={{ fontFamily: "Rubik" }}>
                    <GreenCheckbox
                      name="containsUppercase"
                      checked={containsUppercase}
                      size="medium"
                      checkedIcon={<CheckCircleIcon />}
                      icon={<RadioButtonUncheckedIcon />}
                    />
                    Contain at least one uppercase letter
                  </Typography>
                  <Typography style={{ fontFamily: "Rubik" }}>
                    <GreenCheckbox
                      icon={<RadioButtonUncheckedIcon />}
                      checked={containsNumber}
                      size="medium"
                      checkedIcon={<CheckCircleIcon />}
                      name="containsNumber"
                    />
                    Contain at least one number
                  </Typography>
                  <Typography style={{ fontFamily: "Rubik" }}>
                    <GreenCheckbox
                      icon={<RadioButtonUncheckedIcon />}
                      size="medium"
                      checked={containsSpecialChar}
                      checkedIcon={<CheckCircleIcon />}
                      name="containsSpecialChar"
                    />
                    Contain at least one special character
                  </Typography>
                </div>
        
                <Box component={"div"} style={{ marginTop: "12px",cursor:"pointer" }}>
                  <Typography style={{...styles.labelStyle,cursor:"pointer"}}>
                    Select Country<span style={styles.starStyle}>*</span>
                  </Typography>
                  <Select
                  options={configJSON.countryNames}
                  data-test-id="handlecountry"
                  onChange={(countries) => this.handleCountry(countries)}
                  styles={customStyles}
                  className={this.props.classes.selectmenu}
                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                  placeholder="Select Country"
                />
                </Box>
                {this.state.countryErr && <span className={classes.countryErrorStyle}>
                 * Please Select Country
                </span>}
              
                 <Box style={styles.boxstyle}>
                <Box style={styles.recaptchaStyle}>
                  <ReCAPTCHA 
                   sitekey={this.state.sitekey}
                   id = "handleSelectMainBoxClick"
                   onChange={(value: any) => {
                    this.handleCaptchaValue(value)
                  }}
                  />
                  {this.state.captchaErr.length>0 && <span className={classes.countryErrorStyle}>
                  { this.state.captchaErr}
                  </span>}
                  </Box>
                </Box>
                
                <Button
                  type="submit"
                  variant="contained"
                  style={styles.signinButton}
                  onClick={this.SignUpApi}
                >
                 SIGN UP
                </Button>
                <Typography style={styles.subHeading}>
                  Have an account?{" "}
                  <span style={styles.signUpLink} onClick={this.handleSignIn}>
                  SIGN IN 
                  </span>
                </Typography>
              </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}  className="dots-img">
              <img src={login} style={styles.image} />
            </Grid>
          </Grid>
        </div>
        <img src={ellipse} alt="ellipse" style={styles.ellipseImg}/>
      </MainContainer>
        </div>
      // Customizable Area End
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
      dark: "#3B4E7D"
    }
  }
});
const styles:any = {
  root: {
    flexGrow: 1,
    margin: "0 auto",
    maxWidth: 1440,
    "@media (max-width: 700px)": {
      paddingLeft: "0"
    }
  },
    starStyle:{
    fontWeight: 800,
    color: "#FF5E5B",
},
  invento:{
    width:"264px",
    height:"48px",
    objectFit:"cover" as "cover",
     marginTop: "42px",
     marginLeft:"10px",
     marginBottom: "34px",
     cursor:"pointer"
  },
  recaptchaStyle:{
    marginTop:"1rem",
    cursor:"pointer",
    '& .rc-inline-block':{
    cursor:"pointer",
    }
  },
  signUpLink: {
    color: "#364BA0",
    cursor: "pointer",
    textDecoration: 'underline'
  },
  image: {
    width: "50%",
    height: "auto",
    position:"absolute" as "absolute",
    right:0,
    "@media (max-width: 700px)": {
      width: "",
      height: ""
    }
  },
  heading: {
    fontSize: "36px",
    fontWeight: 700,
    color: "#000000",
    marginTop: "40px",
    marginBottom: "30px",
    fontFamily: "Rubik"
  },
  subHeading: {
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "31px",
    color: "#6F6E6E",
    textDecoration: "none",
    fontFamily: "Rubik"
  },
  headingSign: {
    fontSize: "32px",
    fontWeight: 500,
    color: "#364BA0",
    marginBottom: "31px",
    fontFamily: "Rubik"
  },
  passwordStyle: {
    color: "#000000",
    fontWeight: "bold",
    fontFamily: "Rubik",
    marginTop: "4px",
    fontSize: "16px",
    textAlign: "left"
  },

  formStyle2: {
    maxWidth: "432px",
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important"
    }
  },
  selectOutlined: {
    width: "100%",
  },
  boxstyle: {
    marginBottom: "27px"
  },
  signinButton: {
    marginBottom: "24px",
    height: "55px",
    backgroundColor: "#364BA0",
    color: "#FFFFFF",
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Rubik"
  },
  boxstyle2: {
    display: "flex",
    marginTop: "20px",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width:600px)": {
      display: "block"
    }
  },
  typography: {
    display: "block",
    color: "#848484"
  },
  labelStyle: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#535353",
    marginBottom: "12px",
    fontFamily: "Rubik"
  },
  labelStyle2:{
    fontSize: "20px",
    fontWeight: 500,
    color: "#535353",
    marginBottom: "12px",
    fontFamily: "Rubik",
    marginTop:"10px"
  },
  checkstyle: {
    marginTop: "13px",
    marginBottom: "32px"
  },
  selectMainBox: {
    minHeight: "52px",
    border: "1px solid silver",
    marginBottom: "3px",
    borderRadius: "4px"
  },
  selectBox: {
    padding: "12px",
    width: 432,
    borderRadius: 15,
    background: "#4A4A4A"
  },
  labelStyle3: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Rubik"
  },
  selectedOption: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Rubik"
  },
  removeDecoration: {
    textDecoration: "none",
    color: "#ffffff"
  },
  formError: {
    color: "red",
    fontSize: 14,
    fontFamily: "Rubik"
  },
  position: {
    position: "relative" as const,
    zIndex:1
  },
  county: {
    height: 70,
    position: "relative" as "relative",
  },
  borderAccordian:{
    borderBottom: "1px solid #CBCBCB ",
    height:"50px"
  },
  countryName:{
    fontFamily: "Inter",
    padding:5,
    cursor:"pointer",
  },
  expandedAcc:{
    background:"#FF0000",
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    width:'370px',
    zIndex: "-1"
  }
};
const GreenCheckbox = withStyles({
  root: {
    color: "#535353",
    "&$checked": {
      color: "#33A1E5"
    }
  },
  checked: {}
})(Checkbox);

const GreenCheckbox1 = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const AccordionMain = withStyles({
  root:{
    "& .MuiAccordionSummary-content.Mui-expanded":{
      margin:0
    },
    "& .MuiAccordionDetails-root":{
      display:"block"
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 55
    },
    "& ::-webkit-scrollbar": {
      width: "5PX",
      borderRadius: "10px" as "10px",
      marginLeft: "10px",
    },
    "& ::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px" as "10px",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#206FC4",
      borderRadius: "10px" as "10px"  ,
      height: 50,
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#555",
      borderRadius: "10px" as "10px",
    },
    "& .country-names:hover":{
      background:"#206FC4",
      borderRadius:5,
      marginRight: 10,
      color:"#fff"
    }
  }
})(Accordion);
const webStyle = {
  selectmenu: {
    marginottom:" 10px", 
    fontFamily: "Inter",
    fontSize: "18px",
    "& .css-13cymwt-control": {
      height: "50px"
    },
    "& ::-webkit-scrollbar": {
      backgroundColor: "#F5F5F5",
      marginLeft: "15px",
      width: "6px",
    },
    "& ::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      height: "50px",
      backgroundColor: "#2684FF",
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      backgroundColor: " #2684FF"
    },
    "::-webkit-scrollbar-track:hover": {
      backgroundColor: " #F0F0F0"
    },
    "& .css-1u9des2-indicatorSeparator": {
      display: "none"
    },
    "& .MuiSvgIcon-root": {
      color: "black",
      fontSize: "42px",
    },
    "& .css-qbdosj-Input": {
      margin: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
      gridTemplateColumns: "none",
    },
    "& .css-1xc3v61-indicatorContainer": {
      color: "black",
      padding: "0px",
    },
    "& .css-b62m3t-container ": {
      height: "60px"
    },
    "& .css-1jqq78o-placeholder": {
      color: "black"
    },
    "& .css-151sz6c-indicatorContainer:hover": {
      color: "black"
    },
    "& .css-151sz6c-indicatorContainer": {
      color: "black"
    },
    "& .css-1xc3v61-indicatorContainer:hover ": {
      color: "black"
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      height: "50px"
    },
    "& .css-1nmdiq5-menu": {
      padding: "5px"
    },
    "& .css-l0mvv6-control": {
      cursor: "pointer"
    },
    "& .css-1okebmr-indicatorSeparator":{
      backgroundColor: "#fff"
    }
  },
  countryErrorStyle: {
    marginLeft: 0,
    color: "red",
    fontSize:"12px",
    marginTop:"auto"
  },
}
export default withStyles(webStyle)(EmailAccountRegistration);


// Customizable Area End
