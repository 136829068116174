import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface SocialMediaForm {
    Media_Link: string;
    id: string;
   
  }
// Customizable Area End

interface S {
  // Customizable Area Start
  isDialogOpen: boolean;
  socialMediaForm: SocialMediaForm[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class RemindMeDialogboxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExperienceID:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      socialMediaForm: [{  id: '', Media_Link: '', }],
      isDialogOpen: true,

      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
        }
        if (apiRequestCallId === this.apiExperienceID) {
            console.log(responseJson); 
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   
  }
  handleHomeBtn = () => {
    this.props.navigation.navigate("Dashboard")
    this.setState({ isDialogOpen: false });
  }
  handleCloseDialogBtn = () => {
    this.setState({
         isDialogOpen: false 
        });
  };
  remindMeLaterBtn=()=>{
    this.props.navigation.navigate("CustomForm")
    this.setState({ isDialogOpen: false });
  }











  // Customizable Area End
}