import React from 'react';
// Customizable Area Start
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import FreelancerProfileSettings from './FreelancerProfileSettings.web';
import FreelancerPersonalDetails from './FreelancerPersonalDetails.web'


const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    },
    secondary: {
      main: '#696A6A'
    }
  },
  typography: {
    fontFamily: 'Inter,Rubik'
  }
});
import FreelancerContactInformation from './FreelancerContactInformation.web';
import FreelancerBillingInformation from './FreelancerBillingInformation.web';
// Customizable Area End
import FreelancerSettingsController, { Props } from './FreelancerSettingsController';
import FreelancerPasswordSetting from './FreelancerPasswordSetting.web';
import FreelancerNavBar from '../../dashboard/src/FreelancerNavBar.web';

export default class FreelancerSettings extends FreelancerSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <ThemeProvider theme={theme}>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Grid container style={webStyles.freelacerContainer}>
            <Grid item xs={12} sm={3}>
              <Box style={webStyles.linkBox}>
                <Typography style={webStyles.settingsText}>Settings</Typography>
                {this.state.profileDetails.map((item,index) => 
                    <Link
                    key={index}
                    data-testid={'freelancer-link-' + index}
                    underline="none"
                    onClick={() => this.handleSelectComponent(item.component)}
                    style={
                      this.state.storeData === item.component  ? webStyles.inactiveLinkColor
                      : webStyles.settingLink
                    }
                  >
                    {item.name}
                  </Link>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              {this.state.storeData === 'personalDetails' && (
                <FreelancerPersonalDetails {...this.props} data-testid="personal-details"/>
              )}
              {this.state.storeData === 'profileSettings' && (
               <FreelancerProfileSettings {...this.props} data-testid="my-profile"/>
              )}
              
              {this.state.storeData === 'passwordSecurity' && (
                <FreelancerPasswordSetting {...this.props} data-testid="password-security" isClient={false}/>
              )}
              {this.state.storeData === 'contactInformation' && (
               <FreelancerContactInformation {...this.props} data-testid="my-profile"/>
              )}

              {this.state.storeData === 'billingInformation' && (
               <FreelancerBillingInformation {...this.props} data-testid="my-profile"/>
              )}

            </Grid>
          </Grid>
        </ThemeProvider>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  linkBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexWrap: 'wrap' as 'wrap',
    background: '#F7F9FA',
    borderRadius: '16px',
    padding: "20px 24px"
  },
  settingLink: {
    fontFamily: 'Rubik',
    fontSize:'20px',
    cursor:'pointer',
    color:"0A0A0A",
    marginBottom:"24px"
  },
  freelacerContainer: {
    padding: '3% 6%',
    justifyContent: 'space-evenly'
  },
  settingsText:{
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: 'Rubik',
    marginBottom:"20px"
  },
  inactiveLinkColor:{
    color:"#364BA0",
    borderLeft:"4px solid #364BA0",
    fontFamily: 'Rubik',
    fontSize:'20px',
    cursor:'pointer', 
    marginBottom:"24px",
    paddingLeft:"10px"

  }
};
// Customizable Area End
