import React from "react";
// ...


// Customizable Area Start
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
  withStyles,
  Checkbox,
  Container,
  Button
} from "@material-ui/core";
import { step_22,step_1,step_33,step_4_active,step_5, } from "./assets";



  
// Customizable Area End

import PatentsFormController, {
  Props,
  configJSON
} from "./PatentsFormController.web";
import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import  RemindMeDialogbox  from "./RemindMeDialogbox.web";



type MyComponentProps = {
    children: any,
    index: any,
    value: any,
  };
  
  export class TabPanel extends React.Component<MyComponentProps> {
    render() {
      const { children, value, index, ...other } = this.props 
  
      return (
        <div
          role="tabpanel"
          hidden={value != index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value == index && (
            <Box p={3}>
              {children}
            </Box>
          )}
        </div>
      );
    }
  }
  
  const a11yProps =(index:any)=> {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


export  class PatentsForm extends PatentsFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  
  // Customizable Area End

  render() {
    const { classes ,categoriesInfo } = this.props;
    const { activeTabValue} = this.state;
    const currentSelectedCatItem = this.state.categoriesInfo?.find((catItem: any,index:number) => index == activeTabValue ? catItem : null);
    
    return (
        <>
      <FreelancerNavBar navigation={this.props.navigation} />
      
      < Main_wrapper>
      <Stepper_customPatent_design>
          <Box className="box_1">  <img src={step_1} className="img_stepper"/></Box>
          <Box className="box_1 img_second"> <img src={step_22} className="img_stepper "/></Box>
          <Box className="box_1"> <img src={step_33} className="img_stepper"/></Box>
          <Box className="box_1"> <img src={step_4_active} className="img_stepper"/></Box>
          <Box className="box_1"> <img src={step_5} className="img_stepper"/></Box>
        </Stepper_customPatent_design>

      <Box className={classes?.chooseCategoryDiv}>
            <Typography className={classes?.chooseCategoryHeading}>
            Choose your Patents.
            </Typography>
          </Box>


    <Container
          maxWidth="xl"
          className={classes?.root}
          style={{ position: 'relative' as 'relative' }}
        >
         <Tabs
            value={this.state.activeTabValue}
            data-test-id="tabsChange"
            variant="scrollable"
            scrollButtons="on"
            style={webStyle?.tabColor}
            onChange={this.handleTabsChange}
            aria-label="simple tabs example"
          >
            {this.state.categoriesInfo?.map((category: any, index: number) => (
              <Tab
                key={index}
                label={
                  <Typography
                    style={{
                      fontWeight: 700,
                      color: '#000000',
                      fontSize: 15,
                      textTransform: 'none' as 'none'
                    }}
                  >
                    {category.attributes.name}
                  </Typography>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
           <TabPanelStyle value={activeTabValue} index={activeTabValue}>
            <Grid container spacing={1} direction="row">
            {currentSelectedCatItem?.attributes?.sub_categories?.data?.map(
  (subCategoryItem: any, subCatItemIndex: number) => {
    const subCategoryIds = subCategoryItem.attributes.sub_category_menus.data.map(
      (subCategoryMenuItem: any) => subCategoryMenuItem.attributes.id
    );

    return (
      <Grid item md={3} key={subCatItemIndex}>
        <Box component={'div'} style={webStyle?.accordianHeading}>
          <Typography>{subCategoryItem.attributes.name}</Typography>
        </Box>
        <div style={webStyle?.accordianBody}>
          
          
          {subCategoryItem.attributes.sub_category_menus.data?.map(
            (subCategoryMenuItem: any, subCategoryMenuItemIndex: number) => (
              <Accordion key={subCategoryMenuItemIndex}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header"
                  expandIcon={<ArrowDropDownIcon />}
                >
                <Checkbox
            style={{ color: '#2155c3' }}
            checked={
              this.state.selectedSubCategories[subCategoryItem.attributes.id]?.length === subCategoryIds.length
            }
            onChange={(event) =>

              this.handleCheckboxChange(event, subCategoryItem.attributes.id, subCategoryIds) 
            }
            data-test-id="checkboxClick"

          />
                  <Typography>{subCategoryMenuItem.attributes.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {subCategoryMenuItem.attributes.sub_category_sub_menu.data?.map(
                    (subCatSubMenuItem: any, subCatSubMenuItemIndex: number) => (
                      <Box key={subCatSubMenuItemIndex}>
                        <div style={{ display: "flex", alignItems: "flex-start", marginBottom:"15px" }}>
                        <Checkbox
                    style={{ color: '#2155c3', paddingTop:"2px" }}
                    checked={
                      (this.state.selectedSubCategories[subCategoryItem?.attributes?.id] || [])
                        .includes(subCategoryMenuItem?.attributes?.id)
                    }
                  />
                          <Typography>{subCatSubMenuItem.attributes.title}</Typography>
                        </div>
                      </Box>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
            )
          )}
        </div>
      </Grid>
    );
  }
)}

            </Grid>
          </TabPanelStyle>





          <Grid container justifyContent="space-between" alignItems="center" style={{marginTop:"26px"}}>
      <Grid item>
        <Button variant="outlined" style={webStyle.backButton}
          onClick={this.handlebackpage.bind(this)}
          data-test-id="handlebackpage"
        >
          Back
        </Button>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
          <Button
           variant="text"
           style={webStyle.laterText}
           onClick={()=>{this.doItLater()}}
           data-test-id="doitlater"
         
        >
          I ll Do It Later
        </Button>
          </Grid>
          <Grid item>
          <Button
          variant="contained"
          style={webStyle.nextButton}
          onClick={()=>{this.handleNext()}}
        data-test-id="navidateTOnext"
        >
          Next
        </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
            </Container>
            {this.state.do_It_Later?<>
              <RemindMeDialogbox navigation={undefined} id={""}/>
            </>:""}
            </Main_wrapper>

            
        <FooterWeb />
         
        </>
        //Customizable Area End
      );
  }
}

// Customizable Area Start
const TabPanelStyle = styled(TabPanel)({
  border: "2px solid #2155C3",
  borderRadius: '10px 10px 10px 10px',
  position: 'relative',
  bottom: "2px",
  "& .MuiTab-wrapper ":{
    fontWeight:700,
    color:"#000000",
    fontSize: 14,
    textTransform: 'none'
  },
"& .MuiAccordionSummary-content.Mui-expanded ":{
    margin:0,
    alignItems:"self-start",
  },
  "& .MuiAccordionDetails-root":{
    padding:"0px 33px 16px",
    flexDirection:"column"


  },
  "&.TabPanel-root-49 .MuiAccordionSummary-content":{
    alignItems:"flex-start",
    marginBottom:"14px",

    "& .PrivateSwitchBase-root-67":{
      paddingTop:"2px"
    }
    

  },
 "& .MuiPaper-elevation1 ":{
    boxShadow: "none",
  },
  "& .PatentsForm-checkboxDirection-9":{
    marginBottom:"12px"
  },
  " & .TabPanel-root-49 .MuiAccordionDetails-root": {
    
    maxHeight: "200px",
    overflow: "scroll",
  },
  "& .MuiCollapse-wrapper" :{
    maxHeight: "300px",
    "overflow-y": "scroll",
    "overflow-x": "hidden",
  },

  
})
// Customizable Area End
const webStyle = {
  //Customizable Area Start
  root: {
    "& .MuiTab-wrapper ":{
      fontWeight:700,
      color:"#000000",
      fontSize: 14,
      textTransform: 'none'
    },
    "& .MuiTab-root":{
      padding: '6px 10px',
      maxWidth: 205
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      border: "2px solid #2155C3",
      borderBottom: "none",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      backgroundColor: "white",
      color: "#2155C3",
      zIndex: 1,
      position: 'relative',
      top: 0,
      minHeight: "68px"
    },
    "& .MuiDivider-vertical":{
      height:"64px",
      marginTop:"10px"
    },
    "& .MuiTabs-flexContainer":{
      display: "flex",
    }
  },
  accordianHeading: {
    minHeight: "48px",
    borderRadius: "12px 12px 0 0",
    backgroundColor: "#2155C3",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"10px 20px"
  },
  accordianBody:{
    border:"1px solid gray",
    borderRadius: "0px 0px 12px 12px",
    padding: "12px",

  },
  accordianBody_rapper:{
    display:"flex"
  },
  accordionSubtxt: {
    color: "#2155C3",
  },
  accortxt: {
    fontSize: "12px",
    color: "#2155C3",
    fontWeight: 600,
    display: "flex",
    alignItems: "center"
  },
 
  accortxttwo:{
    fontSize: "12px",
    color: "#8e8f92",
    fontWeight: 600
  },

  MuiAccordionDetailsRoot:{
    flexDirection: 'row' as 'row',
    

  },
  checkboxDirection: {
    display: "flex",
    flexDirection: 'column' as 'column',
    overflowX: "hidden" as const,
    overflow: "auto",
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2155c3',
      borderRadius: '10px',
    },
  },
  tabColor: {
    backgroundColor: "#F5F6FB",
    marginTop: 5
  },
  checkboxDirectiontwo:{
    display: "flex",
    flexDirection: 'column' as 'column',
    height: 200,
    overflowX: "hidden" as const,
    overflow: "auto",
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2155c3',
      borderRadius: '10px',
    },
  },
  heading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#000000",
    marginTop: "44px",
    marginLeft: "10px",
    marginBottom: "50px",
  },
  chooseCategoryDiv: {
    marginLeft: "10px",
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
  active: {
    backgroundColor: "white",
    color: "blue"
  },
  inActive: {
    backgroundColor: "gray",
    color: "black"
  },
  chooseCategoryHeading: {
    fontSize: "39px",
    fontWeight: 600,
    color: "#000000",
    marginBottom: "12px",
    lineHeight: "58px",
    display: "flex",
    justifyContent: 'start',
  },
  chooseCategoryText: {
    fontSize: "14px",
    lineHeight: "17px",
    color: "#868686",
    marginBottom: "12px",
    display: "flex",
    justifyContent: 'center',
  },
  chooseCategoryWarningBox: {
    marginTop: "10px",
    height: "58px",
    backgroundColor: "#FFE6E6",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "12px",
    width: "35%"
  },
  iconImage: {
    marginLeft: "10px",
    marginBottom: "5px",
    marginRight: "5px",
  },
  chooseCategoryWarningBoxText: {
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#FF0C0C",

  },
  mainBox: {
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "120px",
  },
  box1: {
    width: "30%",
    marginTop: "36px",
  },
  box1Heading: {
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
    fontWeight: 600,
  },
  formGroupDiv: {
    display: "flex",
    gap: "3px",
    marginTop: "15px",
    marginLeft: "7px",
  },
  checkBoxColor: {
    color: "#206FC4",
    "&$checked": {
      color: "#206FC4",
    },
  },
  MuiCheckboxColorPrimaryMuiChecked: {
    color: "#206FC4",
  },
  checkBoxText: {
    lineHeight: "15px",
    fontWeight: 500,
    color: "#6E6E6E",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 8,
    "& .MuiCheckbox-root": {
      padding: 0,
      marginRight: 8,
    },
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
    },
    "& .MuiSvgIcon-root": {
      marginTop: "-3px !important",
    },
  },






 
  lowerSectionDiv: {
    marginTop:"auto",
    width: "98%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position:"absolute" as "absolute",
    top:"500px"
  },






  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  laterText:{
    color: '#364BA0',  
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  //Customizable Area End
  
};
const Stepper_customPatent_design = styled(Box)({
  justifyContent:"space-between",
  display:"flex",
  alignItems:"center",
  margin:"30px 0 ",
  "@media (max-width: 900px)": {
    justifyContent:"flex-start",
    flexWrap:"wrap",

  },
  "& .img_stepper":{
    height:"56px",
    width:"100%"
  },
  "& .img_second":{
    width:"36%",
    "@media (max-width: 900px)": {
      width:"auto",
 
  
    },
  "& .box_1":{
    width:"auto",
  },


  }

 
 });
const Main_wrapper = styled(Box)({
  padding: "18px 56px"

})

export default withStyles(webStyle)(PatentsForm);
// Customizable Area End