import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isTyping: boolean;
  isPasswordValid: boolean
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  job: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showJobs: boolean;
  selectedJob: string[];
  country: string;
  showPassword: boolean;
  showCountryDropdown: boolean;
  containsUppercase: boolean;
  containsNumber: boolean;
  containsSpecialChar: boolean;
  additionalTextBoxVisible: boolean;
  additionalTextBoxValue: string;
  emailError: string;
  firstNameErr: string;
  lastNameErr: string;
  passwordErr: string;
  countryErr: string;
  jobErr: string;
  listJobs: any[];
  openCountry:boolean;
  countryError:boolean;
  sitekey: any;
  captchaDone:any;
  captchaErr:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  formikRef: any;
  signUpApi: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isTyping: false,
      isPasswordValid: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      job: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showJobs: false,
      selectedJob: [],
      country: "",
      showPassword: false,
      showCountryDropdown: false,
      containsUppercase: false,
      containsNumber: false,
      containsSpecialChar: false,
      additionalTextBoxVisible: false,
      additionalTextBoxValue: "",
      emailError: "",
      firstNameErr: "",
      lastNameErr: "",
      passwordErr: "",
      countryErr: "",
      jobErr: "",
      captchaErr:"",
      listJobs: [
        { name: 'IP Lawyer' },
        { name: 'IP Enforcement' },
        { name: 'Patent Holder' },
        { name: 'Prototype Developer' },
        { name: 'Patent Illustrator' },
        { name: 'Patent Liscening Expert' },
        { name: 'Patent Database' },
        { name: 'Prototype Agent' },
        { name: 'Inventors' },
        { name: 'Govt.,ngos, msme, companies' },
        { name: 'IP Valuation' },
        { name: 'Others' }
      ],
      openCountry:false,
      countryError:false,
  sitekey:"6LcB2F8pAAAAAOc5aiGNwLr0e3_8ZuqkZLwCDocB",
  captchaDone:""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleSignUPresponse(responseJson);
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
    
  SignUpApi = () => {
    const errors = {
      emailError: this.state.email === "" ? "* Please Enter Email" : "",
      firstNameErr: this.state.firstName === "" ? "* Please Enter First Name" : "",
      lastNameErr: this.state.lastName === "" ? "* Please Enter Last Name" : "",
      countryErr: this.state.country === "" ? "* Please Select Country" : "",
      passwordErr: this.state.password === "" ? configJSON.errorMessage : "",
      // jobErr: this.state.selectedJob.length === 0 ? "* Please Select Job" : "",
      captchaErr: this.state.captchaDone.length === 0 ? "* Please complete the ReCAPTCHA" : "",
    };
  
    this.setState(errors);
  
    if (Object.values(errors).some((error) => error !== "")) {
      return;
    }
  
    const jsonData = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          country: this.state.country,
          // job_title: this.state.selectedJob,
          activated: true,
          role_id: "customer",
        },
      },
    };
  
    this.signUpApi = this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "account_block/accounts",
      body: jsonData,
    });
  };
  
  handleSignUPresponse(responseJson: any) {
    if (
      this.state.email.length === 0 &&
      this.state.firstName.length === 0 &&
      this.state.lastName.length === 0 &&
      this.state.password.length === 0 &&
      this.state.country.length === 0 &&
      this.state.selectedJob.length === 0
    ) {
      alert("please check all fields");
    } else {
      if (responseJson.errors) {
        this.setState({ jobErr: responseJson.errors[0].account });
      } else {
        this.handleSignIn();
      }
    }
  }

  checkPasswordStrength = (password: string) => {
    const containsUppercase = /[A-Z]/.test(password);
    const containsNumber = /\d/.test(password);
    const containsSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    );

    this.setState({
      containsUppercase,
      containsNumber,
      containsSpecialChar
    });
  };

  handleCountry = (countries:any) => {
    this.setState({country:countries,countryErr:""})
  }

  handleSelectMainBoxClick = (type: "job" | "country") => {
    this.setState(prevState => ({
      showJobs: type === "job" ? !prevState.showJobs : prevState.showJobs,
      showCountryDropdown:
        type === "country"
          ? !prevState.showCountryDropdown
          : prevState.showCountryDropdown
    }));
  };

  handleClickAway = () => {
    this.setState({ showJobs: false});
  }
  handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ country: event.target.value as string, countryErr: "" });
  };

  handleJobSelection = (jobTitle: string) => {
    const { selectedJob } = this.state;
    const updatedSelectedJob = [...selectedJob];
    const index = updatedSelectedJob.indexOf(jobTitle);

    if (index === -1) {
      updatedSelectedJob.push(jobTitle);
    } else {
      updatedSelectedJob.splice(index, 1);
    }
    const additionalTextBoxVisible = updatedSelectedJob.includes("Others");
    this.setState({
      selectedJob: updatedSelectedJob,
      additionalTextBoxVisible,
      jobErr: ""
    });
  };

  handleRemoveSelectedJob = (jobTitleToRemove: string) => {
    const { selectedJob } = this.state;
    const updatedSelectedJob = selectedJob.filter(
      (item: any) => item !== jobTitleToRemove
    );
    this.setState({ selectedJob: updatedSelectedJob });
  };

  handleFirstNameChange = (event: any) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/^\s*/, '');
    this.setState({ 
      firstName: sanitizedValue, 
      firstNameErr: "" 
    });
  };
  handleEmailNameChange = (event: any) => {
    const { value } = event.target;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    const sanitizedValue = value.replace(/^\s*/, '');
  
    if (!sanitizedValue.match(emailPattern)) {
      this.setState({
        emailError: "* Please enter a valid email address",
        email: sanitizedValue
      });
    } else {
      this.setState({
        emailError: "",
        email: sanitizedValue
      });
    }
  };
  
  handleLastNameChange = (event: any) => {
  const { value } = event.target;
  const sanitizedValue = value.replace(/^\s*/, '');
  this.setState({ 
    lastName: sanitizedValue, 
    lastNameErr: "" 
  });
};

  handleSignUp = async () => {
    try {
      const formikProps = this.formikRef.current;
      if (formikProps) {
        await formikProps.handleSubmit();
        this.props.navigation.navigate("EmailAccountLoginBlock");
        this.setState({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          country: "",
          job: "",
          showCountryDropdown: false
        });
      }
    } catch (error) {
    }
  };

  handleSignIn = () => {
    setStorageData('role','client')
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };
  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  handleCountryDropdownClick = () => {
    this.setState(prevState => ({
      showCountryDropdown: !prevState.showCountryDropdown 
    }));
  };
    passwordErrorMessage = "* Please enter password";
    passwordErrorMessage3= "* Password must not exceed 12 characters"
    passwordErrorMessage5= "* Password must be at least 4 characters long"

    passwordErrorMessage4 = "* Password must not contain spaces"
    handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPassword = event.target.value;
    
      if (!newPassword) {
        this.setState({ passwordErr: this.passwordErrorMessage });
      
      }
      else if (newPassword.length <4) {
        this.setState({
          passwordErr: this.passwordErrorMessage5
        });
      }
       else if (newPassword.length > 12) {
        this.setState({
          passwordErr: this.passwordErrorMessage3
        });
      } else if (newPassword.includes(" ")) {
        this.setState({
          passwordErr: this.passwordErrorMessage4
        });
      }  else {
        this.setState({ passwordErr: "" });
      }
    
      this.checkPasswordStrength(newPassword);
      this.setState({ password: newPassword });
    };

    handleAddButtonClick() {
      const { additionalTextBoxValue, listJobs } = this.state;
    
      if (additionalTextBoxValue.trim() !== "") {
        this.setState({
          listJobs: [
            ...listJobs,
            {
              name: additionalTextBoxValue
            }
          ],
          additionalTextBoxValue: ""
        });
      }
    }
    handleLogoClick = ()=>{
      this.props.navigation.navigate("LandingPage");
    }
    
  togglePasswordVisibility = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };
  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };
  handleKeyPress = (event: any) => {
    const charCode = event.charCode;
    if (
      (charCode >= 65 && charCode <= 90) ||   // A-Z
      (charCode >= 97 && charCode <= 122) ||  // a-z
      (charCode >= 48 && charCode <= 57) ||   // 0-9
      charCode === 32                         // space
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };
  

  handleCaptchaValue = (value: string) => {
    if (value.length === 0) {
      this.setState({ captchaDone: value, captchaErr: "* Please complete the ReCAPTCHA" });
    } else {
      this.setState({ captchaDone: value, captchaErr: "" });
    }
  };
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }
  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
    },
    secureTextEntry: true
  };

  handleFocus=()=> {
    this.setState({ isTyping: true });
  }

  handleBlur=()=> {
    this.setState({ isTyping: false });
  }
  // Customizable Area End
}
