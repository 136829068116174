// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  Checkbox
} from "@material-ui/core";
import { step_2_active
  ,step_1,step_3,step_4,step_5,addBtn
 } from "./assets";


// Customizable Area End

// Customizable Area Start
import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import { styled } from "@material-ui/styles";
import CustomFormExperienceController, {
  Props,
 
} from "./CustomFormExperienceController.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
// Customizable Area End

export default class CustomForm extends CustomFormExperienceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleNext = this.handleNext.bind(this);


    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Box style={webStyle.mainBox}>

        <Stepper_custom_design>
          <Box className="box_1">  <img src={step_1} className="img_stepper"/></Box>
          <Box className="box_1 img_second"> <img src={step_2_active} className="img_stepper "/></Box>
          <Box className="box_1"> <img src={step_3} className="img_stepper"/></Box>
          <Box className="box_1"> <img src={step_4} className="img_stepper"/></Box>
          <Box className="box_1"> <img src={step_5} className="img_stepper"/></Box>
        </Stepper_custom_design>
      
          <Typography style={webStyle.basicDetailsHead}>
            Add your Work Experience
          </Typography>
          <Box>
  {this.state.experienceForm.map((form, index) => (
    <Box key={form.id}>
      <Box style={webStyle.BoxTwoStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography style={webStyle.labelStyle}>Title *</Typography>
                <FormControl style={webStyle.textField} variant="outlined">
                  <OutlinedInput
                    placeholder="EX: IP Lawyer"
                    value={form.title}
                    onChange={(e) => this.handleExperienceChange(e, form.id, "title")}
                    error={!!this.state.experienceErrordata[index]?.title}
                     data-test-id="Title"

                  />
                  {this.state.experienceErrordata[index]?.title && (
                    <Typography color="error">
                      {this.state.experienceErrordata[index].title}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography style={webStyle.labelStyle}>Company *</Typography>
                <FormControl style={webStyle.textField} variant="outlined">
                  <OutlinedInput
                    placeholder="Ex: Baker McKenzie LLP"
                    value={form.company}
                    onChange={(e) => this.handleExperienceChange(e, form.id, "company")}
                    error={!!this.state.experienceErrordata[index]?.company}
                    data-test-id="Company"

                  />
                  {this.state.experienceErrordata[index]?.company && (
                    <Typography color="error">
                      {this.state.experienceErrordata[index].company}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
  <Typography style={webStyle.labelStyle}>Start Date*</Typography>
  <FormControl style={webStyle.textField} variant="outlined">
    <Select
      value={form.startMonth || ''}
      onChange={(e: any) => this.handleExperienceChange(e, form.id, 'startMonth')}
      data-test-id="exStartdata"
      displayEmpty
      error={!!this.state.experienceErrordata[index]?.startMonth}

    >
      <MenuItem value="" disabled>
        Month
      </MenuItem>
      {[
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ].map((month, idx) => (
        <MenuItem key={idx} value={month}>
          {month}
        </MenuItem>
      ))}
    </Select>
    {this.state.experienceErrordata[index]?.startMonth && (
      <Typography color="error">{this.state.experienceErrordata[index].startMonth}</Typography>
    )}
  </FormControl>
</Grid>

<Grid item xs={12} sm={6}>
  <Typography style={webStyle.withoutlabelStyle}></Typography>
  <FormControl style={webStyle.textField} variant="outlined">
    <Select
      value={form.startYear || ''}
      onChange={(e: any) => this.handleExperienceChange(e, form.id, 'startYear')}
      data-test-id="EXstartYearfield"
      displayEmpty
      error={!!this.state.experienceErrordata[index]?.startYear}
    >
      <MenuItem value="" disabled>
        Year
      </MenuItem>
      {[...Array(50)].map((_, idx) => {
        const year = new Date().getFullYear() - idx;
        return (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        );
      })}
    </Select>
    {this.state.experienceErrordata[index]?.startYear && (
      <Typography color="error">{this.state.experienceErrordata[index].startYear}</Typography>
    )}
  </FormControl>
</Grid>


<Grid item xs={12}>
  <div>
    <Checkbox
      disabled={false}
      checked={form.isCurrentlyWorking || false}
      onChange={(event) => this.handleCheckboxChange(event, form.id)}
      color="primary"
      data-test-id="checkboxClick"

      
    />
    I am currently working in this role
  </div>
</Grid>

<Grid item xs={12} sm={6}>
  <Typography style={webStyle.labelStyle}>End Month</Typography>
  <FormControl style={webStyle.textField} variant="outlined">
    <Select
      value={form.endMonth || ''}
      onChange={(e: any) => this.handleExperienceChange(e, form.id, 'endMonth')}
      data-test-id="ExendMonthfield"
      displayEmpty
      disabled={form.isCurrentlyWorking} 
      error={!!this.state.experienceErrordata[index]?.endMonth}
    >
      <MenuItem value="" disabled>
        Month
      </MenuItem>
      {[
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ].map((month, idx) => (
        <MenuItem key={idx} value={month}>
          {month}
        </MenuItem>
      ))}
    </Select>
    {this.state.experienceErrordata[index]?.endMonth && (
      <Typography color="error">{this.state.experienceErrordata[index].endMonth}</Typography>
    )}
  </FormControl>
</Grid>

<Grid item xs={12} sm={6}>
  <Typography style={webStyle.withoutlabelStyle}></Typography>
  <FormControl style={webStyle.textField} variant="outlined">
    <Select
      value={form.endYear || ''}
      onChange={(e: any) => this.handleExperienceChange(e, form.id, 'endYear')}
      data-test-id="ExendYearfield"
      displayEmpty
      disabled={form.isCurrentlyWorking} 
      error={!!this.state.experienceErrordata[index]?.endYear}
    >
      <MenuItem value="" disabled>
        Year
      </MenuItem>
      {[...Array(50)].map((_, idx) => {
        const year = new Date().getFullYear() - idx;
        return (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        );
      })}
    </Select>
    {this.state.experienceErrordata[index]?.endYear && (
      <Typography color="error">{this.state.experienceErrordata[index].endYear}</Typography>
    )}
  </FormControl>
</Grid>


<Grid item xs={12}>
  <Typography style={webStyle.labelStyle}>
    Description (Optional)
  </Typography>
  <FormControl style={webStyle.textField} variant="outlined">
    <OutlinedInput
      placeholder="Tell us about your experience"
      value={form.description}
      onChange={(e: any) => this.handleExperienceChange(e, form.id, 'description')}
      data-test-id="exdescription"
      fullWidth
      multiline
      minRows={4}
    />
  </FormControl>
</Grid>


            </Grid>
          </Grid>
        </Grid>
      </Box>

      {index === this.state.experienceForm.length - 1 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: "26px" }}
        >
          <Grid item>
            {this.state.experienceForm.length < 5 && (
              <Button
                variant="text"
                style={webStyle.removeText}
                onClick={this.handleAddExperienceForm}
                data-test-id="ExperienceForm_add"
              >
                  <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                Add Experience
              </Button>
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {this.state.experienceForm.length > 1 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={() => this.handleRemoveExperienceForm(form.id)}
                data-test-id="ExperienceForm_remove"

                  >
                    Remove
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  ))}
</Box>



<Box style={webStyle.mainSubBox}>
        <Typography style={webStyle.basicDetailsHead}>Add your Education</Typography>
        {this.state.educationForms.map((form, index) => (
          <Box key={form.id}>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography style={webStyle.labelStyle}>Degree</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      placeholder="EX: B.Sc. in Computer Science"
                      value={form.degree}
                      onChange={(e) => this.handleEducationChange(e, form.id, 'degree')}
                      error={!!this.state.educationerrors[index]?.degree}
                     data-test-id="educationdegree"

                      
                    />
                    {this.state.educationerrors[index]?.degree && (
                      <Typography color="error">{this.state.educationerrors[index].degree}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography style={webStyle.labelStyle}>University Name</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      placeholder="Enter university name"
                      value={form.university}
                      onChange={(e) => this.handleEducationChange(e, form.id, 'university')}
                      error={!!this.state.educationerrors[index]?.university}
                     data-test-id="University_Name"

                    />
                    {this.state.educationerrors[index]?.university && (
                      <Typography color="error">{this.state.educationerrors[index].university}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography style={webStyle.labelStyle}>Country</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      placeholder="Enter country name"
                      value={form.country}
                      onChange={(e) => this.handleEducationChange(e, form.id, 'country')}
                      error={!!this.state.educationerrors[index]?.country}
                     data-test-id="Country_Name"

                    />
                    {this.state.educationerrors[index]?.country && (
                      <Typography color="error">{this.state.educationerrors[index].country}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography style={webStyle.labelStyle}>Year Attended (From)</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <Select
                      value={form.yearFrom}
                      onChange={(e) => this.handleEducationChange(e, form.id, 'yearFrom')}
                      error={!!this.state.educationerrors[index]?.yearFrom}
                     data-test-id="Year_Attended"

                    >
                      <MenuItem value="" disabled>From</MenuItem>
                      <MenuItem value="2024">2024</MenuItem>
                      <MenuItem value="2025">2025</MenuItem>
                      <MenuItem value="2026">2026</MenuItem>
                      <MenuItem value="2027">2027</MenuItem>
                      <MenuItem value="2028">2028</MenuItem>
                    </Select>
                    {this.state.educationerrors[index]?.yearFrom && (
                      <Typography color="error">{this.state.educationerrors[index].yearFrom}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography style={webStyle.labelStyle}>Year Attended (To)</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <Select
                      value={form.yearTo}
                      onChange={(e) => this.handleEducationChange(e, form.id, 'yearTo')}
                      error={!!this.state.educationerrors[index]?.yearTo}
                     data-test-id="educationYearAttended"

                    >
                      <MenuItem value="" disabled>To</MenuItem>
                      <MenuItem value="2024">2024</MenuItem>
                      <MenuItem value="2025">2025</MenuItem>
                      <MenuItem value="2026">2026</MenuItem>
                      <MenuItem value="2027">2027</MenuItem>
                      <MenuItem value="2028">2028</MenuItem>
                    </Select>
                    {this.state.educationerrors[index]?.yearTo && (
                      <Typography color="error">{this.state.educationerrors[index].yearTo}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Description (Optional)</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      placeholder="Describe your studies, awards, etc."
                      value={form.description}
                      onChange={(e) => this.handleEducationChange(e, form.id, 'description')}
                      fullWidth
                      multiline
                      minRows={4}
                     data-test-id="educationDescription"

                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "26px" }}>
              <Grid item>
                {this.state.educationForms.length < 5 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={this.handleAddEducationForm}
                    data-test-id='educationAddBTN'
                  >
                    <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                    Add Education
                  </Button>
                )}
              </Grid>
              <Grid item>
                {this.state.educationForms.length > 1 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={() => this.handleRemoveEducationForm(form.id)}
                    data-test-id='RemoveBTNEduccation'

                  >
                    Remove
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>


      <Box style={webStyle.mainSubBox}>
        <Typography style={webStyle.basicDetailsHead}>Add your Certificate</Typography>
        <Box>
          {this.state.certificateForms.map((form, index) => (
            <Box key={form.id}>
              <Box style={webStyle.BoxTwoStyle}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Certificate name</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Ex: IPR Certification"
                        value={form.certificate_name}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'certificate_name')}
                        error={!!this.state.certificateerrors[index]?.certificate_name}
                        data-test-id='Certificatename'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Certificate provider</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Ex: ICAI"
                        value={form.certificate_provider}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'certificate_provider')}
                        error={!!this.state.certificateerrors[index]?.certificate_provider}
                        data-test-id='certificateprovider'

                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Certificate Id (Optional)</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Certification ID here"
                        value={form.certificate_id}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'certificate_id')}
                        error={!!this.state.certificateerrors[index]?.certificate_id}
                        data-test-id='CertificateId'

                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Certificate Url (Optional)</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter URL here"
                        value={form.certificate_url}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'certificate_url')}
                        error={!!this.state.certificateerrors[index]?.certificate_url}
                        data-test-id='CertificateUrl'

                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Issue Date</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        type="date"
                        value={form.certificate_issue}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'certificate_issue')}
                        error={!!this.state.certificateerrors[index]?.certificate_issue}
                        data-test-id='CertificateIssueDate'

                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Expiration Date (Optional)</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        type="date"
                        value={form.certificate_expire_date}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'certificate_expire_date')}
                        error={!!this.state.certificateerrors[index]?.certificate_expire_date}
                        data-test-id='CertificateExpirationDate'

                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '26px' }}>
                <Grid item>
                  {this.state.certificateForms.length < 5 && (
                    <Button variant="text" style={webStyle.removeText} onClick={this.handleAddcertificateForm}>
                       <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                      Add Certificate
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {this.state.certificateForms.length > 1 && (
                    <Button variant="text" style={webStyle.removeText} onClick={() => this.handleRemovecertificateForm(form.id)}>
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>

      <Box style={webStyle.mainSubBox}>
        <Typography style={webStyle.basicDetailsHead}>Add your License</Typography>
        <Box>
          {this.state.licenseForms.map((form, index) => (
            <Box key={form.id}>
              <Box style={webStyle.BoxTwoStyle}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Licensed Profession</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Ex: Architect"
                        value={form.Licensed_Profession}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Licensed_Profession')}
                        error={!!this.state.licenseErrors[index]?.Licensed_Profession}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Licensing Body</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Ex: State Board"
                        value={form.Licensing_Body}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Licensing_Body')}
                        error={!!this.state.licenseErrors[index]?.Licensing_Body}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>License Number</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter License Number here"
                        value={form.License_Number}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'License_Number')}
                        error={!!this.state.licenseErrors[index]?.License_Number}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Licensing Verification URL (Optional)</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter URL here"
                        value={form.Licensing_Verification_URL}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Licensing_Verification_URL')}
                        error={!!this.state.licenseErrors[index]?.Licensing_Verification_URL}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Issue Date</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        type="date"
                        value={form.Issue_Date}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Issue_Date')}
                        error={!!this.state.licenseErrors[index]?.Issue_Date}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={webStyle.labelStyle}>Expiration Date (Optional)</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        type="date"
                        value={form.Expiration_Date}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Expiration_Date')}
                        error={!!this.state.licenseErrors[index]?.Expiration_Date}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '26px' }}>
                <Grid item>
                  {this.state.licenseForms.length < 5 && (
                    <Button variant="text" style={webStyle.removeText} onClick={this.handleAddLicenseForm}>
                       <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                        style={{width: "100%",  height: "34",maxWidth: "150px",  margin: "0 auto",  display: "block",  }} />
                  </Box>
                      Add License
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {this.state.licenseForms.length > 1 && (
                    <Button variant="text" style={webStyle.removeText} onClick={() => this.handleRemoveLicenseForm(form.id)}>
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>

     





        <Grid container justifyContent="space-between" alignItems="center" style={{marginTop:"26px"}}>
      <Grid item>
        <Button variant="outlined" style={webStyle.backButton}
          onClick={this.handlebackpage.bind(this)}
          data-test-id="handlebackpage"
        >
          Back
        </Button>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
          <Button
           variant="text"
           style={webStyle.laterText}
         
        >
          I ll Do It Later
        </Button>
          </Grid>
          <Grid item>
          <Button
          variant="contained"
          style={webStyle.nextButton}
          onClick={this.handleNext}
        data-test-id="navidateTOnext"
        >
          Next
        </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
       


    </Box>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }

  
}

const webStyle = {
  mainBox:{
    padding:"56px"
  },
  mainSubBox:{
    marginTop:"28px"
  },
  basicDetailsHead:{
    fontFamily:"Rubik",
    fontSize:"36px",
    fontWeight:600,
  },
  BoxTwoStyle:{
    border: "1px solid #B1B1B1",
    padding: "28px",
    borderRadius:"12px",
    marginTop:"20px"
  },
  imageBoxStyle:{
    display:"flex",
    gap :"48px"
  },
  nameStyle:{
    fontFamily:"Rubik",
    fontSize:"28px",
    fontweight:500,
    color:"#0A0A0A"
  },
  emailStyle:{
    fontFamily:"Rubik",
    fontSize:"18px",
    color:"#0A0A0A"
  },
  updateProfileBtn:{
    color: "#364BA0",
  border: "1px solid #364BA0",
  borderRadius: "26px",
  marginTop: "16px",
  display: "flex",
  justifyContent: "center",
  padding: "10px",
  fontSize: "20px",
  fontFamily:"Rubik",
  fontWeight: 500
  },
  labelStyle:{
    fontFamily:"Rubik",
    fontSize:"16px",
    color:"#0A0A0A",
    fontWeight:500,
    marginBottom:"8px"
  },
  withoutlabelStyle:{
    fontFamily:"Rubik",
    fontSize:"16px",
    color:"#0A0A0A",
    fontWeight:500,
    marginBottom:"8px",
paddingTop:"23px"
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: "12px !important"
    }
  },
   
  chipStyle: {
    background: "#364BA0",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  buttonStyle: {
    margin: '10px 0',
    fontWeight: 500,
  },
  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  iconStyle: {
    color: '#fff',  
    fontSize: '20px',  
  },
  laterText:{
    color: '#364BA0',  
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  removeText:{
    color: '#364BA0',  
    textTransform: 'initial' as 'initial',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  }
};
const Stepper_custom_design = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  margin:"30px 0 ",
  "@media (max-width: 900px)": {
    justifyContent:"flex-start",
    flexWrap:"wrap",

  },
  "& .img_stepper":{
    height:"56px",
    width:"100%"
  },
  "& .box_1":{
    width:"auto",
  },
  "& .img_second":{
    width:"36%",
    "@media (max-width: 900px)": {
      width:"auto",
    },

  }

 
 });
 

// Customizable Area End